<template>
  <div class="home-wrap">
    <div class="home-content">
      <div v-if="!isComp" class="table-page-search-wrapper">
        <a-form layout="inline" :label-col="{ span: 4 }" :wrapper-col="{ span: 16 }">
          <a-row :gutter="24">
            <a-col :span="6">
              <a-form-item label="学生">
                <a-input
                  placeholder="输入学生姓名或学号"
                  v-model="studentCondition"
                  allow-clear
                  @pressEnter="onSearchReport"
                />
              </a-form-item>
            </a-col>

            <a-col :span="6">
              <a-form-item label="任务名称">
                <a-input placeholder="请输入" v-model="taskName" allow-clear @pressEnter="onSearchReport" />
              </a-form-item>
            </a-col>
            <a-col :span="6" v-if="showSubject">
              <a-form-model-item label="科目">
                <a-select placeholder="请选择" allowClear @change="changeSubjects" mode="multiple" v-model="subjects">
                  <a-select-option :value="item.value" v-for="(item, index) in subjectsArr" :key="index">
                    {{ item.label }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <!-- <a-col :span="6">
                <a-form-model-item label="任务状态">
                  <a-select placeholder="请选择" allowClear @change="onSearchReport" v-model="taskStatus">
                    <a-select-option :value="item.value" v-for="(item, index) in taskStatusArr" :key="index">
                      {{ item.label }}
                    </a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-col> -->
            <a-col :span="6">
              <a-form-item label="任务类型">
                <a-select
                  show-search
                  allowClear
                  mode="multiple"
                  :filter-option="filterOption"
                  option-filter-prop="children"
                  placeholder="请选择"
                  v-model="taskTypeName"
                >
                  <a-select-option :value="item.value" v-for="(item, index) in taskTypeArr" :key="index">
                    {{ item.label }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="6">
              <a-form-item label="任务等级">
                <a-select
                  show-search
                  allowClear
                  :filter-option="filterOption"
                  option-filter-prop="children"
                  placeholder="请选择"
                  v-model="depthService"
                >
                  <a-select-option
                    :value="item[0]"
                    v-for="item in Object.entries(TaskStudentDepthServiceEnum)"
                    :key="item[0]"
                  >
                    {{ item[1] }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="6" v-if="this.activeName === 'nofinish' || this.activeName === 'finished'">
              <a-form-item label="任务时段">
                <a-range-picker @change="onChangeTableDate" v-model="queryClassDateArr" format="YYYY-MM-DD" />
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24" style="float: right; text-align: right">
              <span class="table-page-search-submitButtons" :style="{ float: 'right', overflow: 'hidden' } || {}">
                <a-button type="primary" style="margin-right: 10px" @click="onSearchReport">查询</a-button>
                <a-button style="margin-right: 10px" @click="resetCondition">重置</a-button>
              </span>
            </a-col>
            <a-col :span="6" v-if="userInfo.leader">
              <a-form-item label="组织(部门)">
                <a-tree-select
                  allow-clear
                  v-model="deptId"
                  style="width: 100%"
                  :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                  :tree-data="treeData"
                  placeholder="请选择"
                  tree-default-expand-all
                >
                </a-tree-select>
              </a-form-item>
            </a-col>
            <a-col :span="6" v-if="userInfo.leader">
              <a-form-item label="执行人">
                <a-select
                  show-search
                  @search="searchClassAdmin"
                  v-model="assigneeId"
                  :filter-option="filterOption"
                  option-filter-prop="children"
                  placeholder="请选择"
                  allow-clear
                >
                  <a-select-option :value="i.uuid" v-for="i of classAdminList" :key="i.uuid">
                    {{ i.fullName }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <div :class="['table-wrap', { comp: isComp }]">
        <div class="sel-navwrap">
          <div class="sel-left">
            <a-select
              class="sel-task"
              :default-value="selTabData[0].value"
              style="width: 110px; font-weight: bold"
              @change="handleTabClick"
            >
              <a-select-option v-for="item in selTabData" :key="item.value">
                {{ item.label }}
              </a-select-option>
            </a-select>
            <template v-if="!isComp && activeName === 'nofinish'">
              <a-divider type="vertical" />
              <a-radio-group @change="onChangeType" v-model="noFinishFlag">
                <a-radio value="TOTAL">All({{ allCount }}) </a-radio>
                <a-radio value="OVERTIME">已逾期({{ overTimeCount }}) </a-radio>
                <a-radio value="TODAY_OVERTIME">未来24H({{ toDayOverTimeCount }}) </a-radio>
                <a-radio value="FUTURE_THREE">未来3天({{ futureThreeCount }}) </a-radio>
                <a-radio value="FUTURE_SEVEN">未来7天({{ futureSevenCount }}) </a-radio>
              </a-radio-group>
            </template>
          </div>
          <div class="sel-right" v-if="!isComp">
            <span class="switch-wrap" v-show="activeName === 'nofinish' && this.userInfo.roleName === 'CLASS_ADMIN'">
              只看8点 ~ 22点学员任务
              <a-switch :value="filterTimeStatus" @click="onChangeFilterTimeStatus" />
            </span>
            <span class="operator-btn" @click="addTask"> 新建任务 </span>
          </div>
        </div>
        <table-list
          activeName="nofinish"
          v-show="activeName === 'nofinish'"
          :optionsType="options"
          :pagination="nofinishPagination"
          :columns="noFinishColumns"
          :tableData="tableNoFinishData"
          :showRow="showRow"
          :loading="tableLoading"
          :countryOptions="countryOptions"
          @selectChange="onSelectChange"
          @handleTableChange="handleTableChange"
          @handleOperate="handleOperate"
          :onFinishUploadTask="onFinishUploadTask"
        ></table-list>
        <table-list
          activeName="finished"
          v-show="activeName === 'finished'"
          :pagination="finishPagination"
          :optionsType="options"
          :columns="finishColumns"
          :loading="tableFinishLoading"
          :countryOptions="countryOptions"
          :tableData="tableFinishData"
          @handleTableChange="handleTableChange"
          @handleOperate="handleOperate"
        ></table-list>
        <table-list
          activeName="copyme"
          v-show="activeName === 'copyme'"
          :optionsType="options"
          :pagination="copyMePagination"
          :columns="copyMeColumns"
          :loading="tableCopyLoading"
          :countryOptions="countryOptions"
          :tableData="tableCopyMeData"
          @handleTableChange="handleTableChange"
          @handleOperate="handleOperate"
        ></table-list>
        <table-list
          activeName="createme"
          v-show="activeName === 'createme'"
          :optionsType="options"
          :pagination="createMePagination"
          :columns="createMeColumns"
          :loading="tableCreateLoading"
          :countryOptions="countryOptions"
          :tableData="tableCreateMeData"
          @handleTableChange="handleTableChange"
          @handleOperate="handleOperate"
        ></table-list>
      </div>
    </div>
    <record-modal
      :taskObj="taskObj"
      type="task"
      v-if="visible"
      :visible="visible"
      @refleshRecord="refleshRecord"
      @closeModal="closeRecordModal"
    ></record-modal>
    <ConfirmLesson
      titleTxt="确认首课时间"
      :showInfoData="showInfoData"
      v-if="visibleModal"
      :studentInfo="studentInfo"
      @handleCancelFirstDate="handleCancelFirstDate"
      @handleOkFirstDate="handleOkFirstDate"
    ></ConfirmLesson>
    <a-modal
      title="确认首课时间"
      :visible="noTimevisible"
      @ok="handleNoFirstDateTC"
      @cancel="handleCancelNoDate"
      okText="去排课"
      cancelText="稍后"
    >
      <!-- <p>你还没有为学生{{ currentStudent.processVariables.studentName }}排课</p> -->
      <p>请先完成排课后，再重新确认首课时间</p>
    </a-modal>

    <!--完成补升-->
    <complete-promotion
      v-if="finishMakeUpFlag"
      :promotionObj="promotionObj"
      @completeMakeUp="completeMakeUp"
      @cancelMakeUp="cancelMakeUp"
    ></complete-promotion>

    <!--补升超期提醒-->
    <OverduePromotion
      v-if="makeUpOverdue !== null"
      :makeUpOverdue="makeUpOverdue"
      @onMakeUpOverdue="onMakeUpOverdue"
      @cancelMakeUpOverdue="cancelMakeUpOverdue"
    ></OverduePromotion>

    <!--添加任务-->
    <a-modal title="新建任务" :visible="approvalFlag" :footer="null" @cancel="cancelApproval">
      <div class="approval-wrap">
        <div class="approval-item" @click="clickApproval" :class="{ selActive: approvalActiveIndex === 1 }">
          <p>分享赠课审批</p>
          <p>学生分享赠课链接确认</p>
        </div>
        <div
          class="approval-item"
          v-if="this.userInfo.roleName === 'HEAD_TEACHER_SUPER'"
          @click="clickTakeOver"
          :class="{ selActive: approvalActiveIndex === 2 }"
        >
          <p>交接学生</p>
          <p>选择离职交接或普通交接</p>
        </div>
      </div>
    </a-modal>
    <!--排课相关得详情-->
    <a-drawer title="任务详情" placement="right" width="700" :visible="visibleCourseTC" @close="onCloseCourse">
      <div class="spin-wrap" v-show="!visibleCourse">
        <a-spin />
      </div>
      <TaskInfo
        :hideBtnRole="hideBtnRole"
        subDetailType="classAdmin"
        :detailType="taskInfoDataType"
        :courseScheduleArr="courseScheduleArr"
        :formInfoData="formInfoData"
        :operatevisibleWrap="operatevisibleWrap"
        @onGetformData="onGetformData"
        @initOperateVisible="initOperateVisible"
        :baseUrl="baseUrl"
        @onGetStudentId="onGetStudentId"
        @onHrefClue="onHrefClue"
        @sendStudyReport="sendStudyReport"
        @playCourseDetail="playCourseDetail"
        @playClassDetail="playClassDetail"
        @openConTaskDeatil="openConTaskDeatil"
        @onMakeUp="onMakeUpHandle"
        @onOrder="onOrder"
        @courseScheduleInfo="courseScheduleInfo"
        v-if="visibleCourse"
      />
    </a-drawer>
    <!--新班主任里面的学生详情-->
    <a-drawer
      title="交接学员详情"
      :maskClosable="false"
      :width="700"
      :visible="visibleAdminStudentInfo"
      :body-style="{ paddingBottom: '80px' }"
      @close="closeStudentModal"
    >
      <lookStudentInformation :data="paramsData" v-if="visibleAdminStudentInfo"></lookStudentInformation>
    </a-drawer>
    <!--打开详情里面关联任务的详情-->
    <a-drawer title="任务详情" placement="right" width="700" :visible="visibleSubCourse" @close="onCloseSubCourse">
      <TaskInfo
        :hideBtn="hideBtn"
        subDetailType="classAdmin"
        :detailType="subConType"
        :courseScheduleArr="courseSubScheduleArr"
        :formInfoData="formSubInfoData"
        :baseUrl="baseUrl"
        @onGetStudentId="onGetStudentId"
        @playCourseDetail="playCourseDetail"
        @openConTaskDeatil="openConTaskDeatil"
        v-if="visibleSubCourse"
      />
    </a-drawer>
    <!--创建离职交接任务-->
    <ResignationHandover v-if="showResignModal" @cancel="cancelResign"></ResignationHandover>
    <!-- <component :is="currentView"  :currentViewData="currentViewData" @approvaCancel="approvaCancel"></component> -->
    <SupplementaryApproval
      v-if="currentView === 'SupplementaryApproval'"
      :currentViewData="currentViewData"
      @approvaCancel="approvaCancel"
    ></SupplementaryApproval>
    <ApproveType v-if="showApprovalModal" @cancal="cancal"></ApproveType>
    <!--创建群操作-->
    <CreateGroup v-if="groupVisible" :createGroupObj="createGroupObj" @cancelGroup="handleCancelGroup"></CreateGroup>
    <!--抽屉里面的完成操作-->
    <a-drawer title="操作" placement="right" width="700" :visible="operatevisibleTC" @close="clearnCurrentView">
      <component
        :is="currentViewTC"
        :taskTypeName="tableListItem.name"
        :taskStatus="taskStatusTC"
        :baseUrl="baseUrl"
        @onSubmit="onGetformData"
        @clearnCurrentView="clearnCurrentView"
        source="classAdmin"
      ></component>
    </a-drawer>
    <!--学员激活里面的完成-->

    <a-modal
      width="550px"
      title="完成任务"
      :visible="visibleActive"
      @ok="saveActive"
      @cancel="cancelActive"
      okText="提交"
      cancelText="取消"
    >
      <a-form :form="activeForm" :label-col="{ span: 7 }" :wrapper-col="{ span: 16 }">
        <a-form-item label="冻结/延期结束时间">
          <a-range-picker
            v-decorator="['queryActiveDateArr', { rules: [{ required: true, message: '请选择延期/冻结时间!' }] }]"
            format="YYYY-MM-DD"
          />
        </a-form-item>
        <a-form-item label="补充说明">
          <a-textarea placeholder="请输入" :auto-size="{ minRows: 3, maxRows: 5 }" v-decorator="['remark']" />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
import { TaskInfo, TestOperate } from '@wk/tch-sc';
import storage from 'store';
import sessionStorage from 'store/storages/sessionStorage';
import { ACCESS_TOKEN } from '@/store/mutation-types';
import ConfirmLesson from './ConfirmLesson.vue';
import SupplementaryApproval from './SupplementaryApproval.vue';
import { getUserUUID } from '@/utils/user';

import {
  addCommentsDetail,
  changeClassAdmin,
  checkPromote,
  completeTaskDetail3,
  completeTaskDetail1,
  completeTaskDetail2,
  completeTaskDetail,
  confirmFirstLesson,
  copyMeTableList,
  copyMeTaskList,
  copyTaskDetail,
  courseScheduleInfo,
  createGroup,
  createMeTableList,
  createMeTaskList,
  feedBackDetail,
  finishedTaskList,
  getFinishedTableList,
  getFirstLesson,
  getTaskStatistics,
  getTaskTableList,
  getTaskTypeArr,
  getTreeList,
  getUploadOssToken,
  initOptions,
  orderInfo,
  playbackCourse,
  queryCalendar,
  queryCourseSchedule,
  queryKnowledgeSummery,
  queryTrailScheduleDetail,
  queryStudentInfoData,
  queryStudentOtherTask,
  rejectTaskDetail,
  selClassAdmin,
  sendPosterGroup,
  studentReminderActive,
  turnTaskDetail,
  getSelfBookingInfo,
  queryStudentDetailData,
  getHandleCourseList,
  queryDaylightReadInfo as queryDaylightReadInfoApi,
} from '@/api/headTeacher';
import { imgpostUrl } from '@/api/register';
import { queryPlayCourse } from '@/api/schedule';
import WeekCalendar from '@/components/WeekCalendar';
import timeUtil from '@/components/WeekCalendar/calendar';
import { getDdlLabel } from '@/utils/util';
import ApproveType from '@/views/mainHome/ApproveType.vue';
import lookStudentInformation from '@/views/mainHome/lookStudentInformation.vue';
import recordModal from '@/views/mainHome/recordModal.vue';
import CompletePromotion from '@/views/operationComponents/CompletePromotion.vue';
import CreateGroup from '@/views/operationComponents/CreateGroup.vue';
import OverduePromotion from '@/views/operationComponents/OverduePromotion.vue';
import ResignationHandover from './ResignationHandover.vue';
import selList from './selList';
import tableList from './tableList';
import { TaskStudentDepthServiceEnum } from '@/enum';
import { getReplayLink } from '@/api/common';
import { reportEvent } from '@/utils/gatherer';
import { submitRecord } from '@/api/headTeacher.js';
import { getCountryOptions } from '@/utils/geoLocation';

const session = storage.createStore(sessionStorage);

const _token = storage.get(ACCESS_TOKEN);

export default {
  name: 'MainHome',
  components: {
    recordModal,
    WeekCalendar,
    selList,
    tableList,
    TaskInfo,
    MyTestOperate: TestOperate,
    SupplementaryApproval,
    ApproveType,
    ConfirmLesson,
    CreateGroup,
    CompletePromotion,
    OverduePromotion,
    ResignationHandover,
    lookStudentInformation,
  },
  computed: {
    isComp() {
      return this.mode === 'component';
    },
    scoure() {
      if (this.mode === 'component') {
        return 'studentTaskList';
      }
      if (this.mode === 'page') {
        return 'list';
      }
      return '';
    },
  },
  props: {
    // 学生详情需要复用这个任务列表，所以需要加一些判断，
    // 如果mode是component的话就是组件复用，会有一些逻辑判断
    // 关注isComp这个字段
    mode: {
      type: String,
      default: 'page',
    },
  },
  props: {
    // 学生详情需要复用这个任务列表，所以需要加一些判断，
    // 如果mode是component的话就是组件复用，会有一些逻辑判断
    // 关注isComp这个字段
    mode: {
      type: String,
      default: 'page',
    },
    compFromStudentCode: {
      type: String,
    },
  },
  data() {
    return {
      TaskStudentDepthServiceEnum,
      depthService: undefined,
      trailCourseScheduleId: '',
      showAllFlag: true,
      getDdlLabel,
      liveUrl: '',
      showSubject: false,
      subjects: [],
      subjectArr: [
        { label: '中文', value: 'CHINESE' },
        { label: '数学', value: 'MATH' },
        { label: '英文', value: 'ENGLISH' },
      ],
      subjectsArr: [
        { value: '【中文】', label: '中文' },
        { value: '【数学】', label: '数学' },
        { value: '【英文】', label: '英文' },
      ],
      deptId: undefined,
      treeData: [],
      activeForm: this.$form.createForm(this),
      queryActiveDateArr: [],
      visibleActive: false,
      showStudentActive: false,
      operatevisibleTC: false,
      taskStatusTC: false,
      currentViewTC: null,
      hideBtnRole: false,
      assigneeId: undefined,
      classAdminList: [],
      queryClassDateArr: [],
      queryStartTime: '',
      queryEndTime: '',
      queryTaskId: '',
      visibleCourseTC: false,
      showLoading: false,
      allCount: 0,
      tableSorter: {},
      noFinishSorter: {},
      finishSorter: {},
      createSorter: {},
      copymeSorter: {},
      taskInfoDataType: '',
      overTimeCount: 0,
      futureCount: 0,
      toDayOverTimeCount: 0,
      futureThreeCount: 0,
      futureSevenCount: 0,
      noFinishFlag: 'TOTAL',
      taskTypeName: [],
      taskTypeArr: [],
      selTabData: [
        { label: '待办任务', value: 'nofinish' },
        { label: '已办任务', value: 'finished' },
        { label: '抄送我任务', value: 'copyme' },
        { label: '我创建任务', value: 'createme' },
      ],
      paramsData: {},
      studentInfoData: {},
      visibleAdminStudentInfo: false,
      classSection: '',
      showResignModal: false,
      approvalActiveIndex: 0,
      taskStatusArr: [
        { label: '已完成', value: 'FINISHED' },
        { label: '待处理', value: 'PENDING' },
        { label: '已拒绝', value: 'REFUSED' },
      ],
      taskName: '',
      taskStatus: undefined,
      promotionObj: {},
      createGroupObj: {},
      showInfoData: {},
      studentInfo: {},
      showApprovalModal: false,
      approvalFlag: false,
      selFlag: false,
      currentView: null,
      currentViewData: null,
      makeUpOverdue: null,
      taskCreateMeData: [],
      hideBtn: false,
      courseSubScheduleArr: [],
      formSubInfoData: {},
      subConType: '',
      visibleSubCourse: false,
      courseScheduleArr: [],
      studentCondition: '',
      studentCode: '',
      //  promoteFlag: 'PURCHASED',
      checkMakeUp: false,
      makeUpFlag: 1,
      radioStyle: {
        display: 'block',
        height: '30px',
        lineHeight: '30px',
      },
      finishMakeUpFlag: false,
      reportSubject: '',
      nofinishFirstFlag: true,
      finishFirstFlag: true,
      copyFirstFlag: true,
      createFirstFlag: true,
      tableCopyLoading: false,
      tableCreateLoading: false,
      tableFinishLoading: false,
      delayshow: true,
      upComingTypeValues: 'UpComing',
      initPagination: {
        total: 0,
        current: 1,
        pageSize: 10, // 每页中显示10条数据
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '50', '100'], // 每页中显示的数据
        showTotal: (total) => `共有 ${total} 条数据`, // 分页中显示总的数据
      },
      nofinishPagination: {},
      finishPagination: {},
      copyMePagination: {},
      createMePagination: {},
      currentPage: 1,
      pageSize: 10,
      tableListItem: {}, // 某一行表格数据
      operatevisibleWrap: true,
      operateTaskId: '',
      baseUrl: process.env.VUE_APP_API_IM_URL,
      assigneesList: [],
      formInfoData: {},
      visibleCourse: false,
      options: {},
      taskInfoCreateMeData: {},
      taskInfoCopyMeData: {},
      taskInfoFinishData: {},
      taskInfoNoFinishData: {},
      activeName: 'nofinish',
      processInstanceId: '',
      groupName: '',
      groupID: '',
      tableLoading: false,
      scrollTable: { y: 600 },
      turnOverGroups: [],
      showRow: false,
      today: timeUtil.dateFormatOther(new Date()),
      endTime: '',
      startTime: '',
      markDateMore: [], // 标记有任务得点
      groupVisible: false,
      firstLesson: '',
      studentName: '',
      dateFormat: 'YYYY-MM-DD HH:mm',
      currentStudent: {},
      noTimevisible: false,

      visibleModal: false,
      taskObj: {},
      userInfo: {},
      visible: false,
      selectedRowKeys: [],
      selectedRows: [],
      taskNoFinishData: [],
      taskFinishData: [],
      taskCopyMeData: [],
      tableFinishData: [],
      tableCopyMeData: [],
      tableCreateMeData: [],
      tableNoFinishData: [],
      taskInfoData: {},
      noFinishColumns: [],
      finishColumns: [],
      copyMeColumns: [],
      createMeColumns: [],
      feedBackDeta: null,
      filterTimeStatus: false,
      receiverProfile: null,
      countryOptions: [],
    };
  },

  methods: {
    async onFinishUploadTask(params) {
      await submitRecord(params);

      reportEvent({
        data: {
          _event: 'CAstudio_TDFinishClick',
          User_id: getUserUUID(),
          Source: this.isComp ? '学生任务列表' : '任务列表',
          Td_id: params.taskId,
          Code: params.studentCode ?? '',
        },
      });

      this.refreshTaskTable();
      this.$message.success('已完成');
    },
    // 点击任务名称的处理
    handleOpenTaskDetail(record) {
      reportEvent({
        data: {
          _event: 'CAstudio_TDCheckClick',
          User_id: getUserUUID(),
          Source: this.isComp ? '学生任务列表' : '任务列表',
          Td_id: record.id,
          Code: record?.processVariables?.studentCode ?? '',
        },
      });
      // 新增的离线任务在tabList组件里面，这里return出去了
      if (record.type === 'OFFLINE_UPLOAD') {
        return;
      }

      // 判断当前节点处理人==当前登录用户
      this.hideBtnRole = this.userInfo.userID === record.assignee;
      this.visibleCourseTC = true;
      this.showLoading = true;
      this.handleOperate('lookFeedBack', record);
      console.log('record', record);
    },

    handleFormHome() {
      const type = this.$route.query.formHomeType;
      if (!type) return;

      if (['OVERTIME', 'TODAY_OVERTIME'].includes(type)) {
        this.noFinishFlag = type;
        return;
      }
      this.taskTypeName = [type];
    },
    changeSubjects(val) {
      this.showAllFlag = false;
      console.log(val);
      this.taskTypeName = [];
      this.getTaskTypeArr();
    },
    getTreeList() {
      getTreeList().then((res) => {
        this.treeData = res.data;
      });
    },
    cancelActive() {
      this.visibleActive = false;
    },
    saveActive(e) {
      e.preventDefault();
      this.activeForm.validateFields((err, values) => {
        if (!err) {
          const params = {
            message: values.remark,
            freezeFromDate: values.queryActiveDateArr[0].format('YYYY-MM-DD'), // 冻结开始时间
            freezeToDate: values.queryActiveDateArr[1].format('YYYY-MM-DD'), // 冻结结束时间
          };
          this.onGetformData({ taskType: 'complate', selfActive: true, formData: params });
        }
      });
    },
    cancelStudentActive() {
      this.showStudentActive = false;
    },
    clearnCurrentView() {
      this.operatevisibleTC = false;
      this.currentViewTC = null;
    },
    searchClassAdmin(name) {
      const params = {
        name,
      };
      selClassAdmin(params).then((res) => {
        this.classAdminList = res.data.content;
      });
    },
    onChangeTableDate(date) {
      if (date.length > 0) {
        this.queryStartTime = date[0].format('YYYY-MM-DD');
        this.queryEndTime = date[1].format('YYYY-MM-DD');
      } else {
        this.queryStartTime = null;
        this.queryEndTime = null;
      }
    },
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },
    getSearchSubjects() {
      if (this.userInfo.roleArr.includes('VIRTUAL_CLASS_ADMIN')) {
        return undefined;
      }
      return this.getSubjectsArr();
    },
    getTaskStatistics() {
      const params = {
        categorySet: this.taskTypeName,
        subjects: this.getSearchSubjects(),
        assigneeId: this.assigneeId,
        dueDateStart: this.queryStartTime,
        deptId: this.deptId,
        dueDateEnd: this.queryEndTime,
        // startTime: `${this.today}T23:59:59`,
        studentCondition: this.studentCondition,
        taskName: this.taskName,
        taskStatus: this.taskStatus,
        // endTime: `${this.today}T23:59:59`
        onlyWaking: this.filterTimeStatus,
        depthService: this.depthService,
      };
      if (this.$route.query.studentCode && this.showAllFlag) {
        params.showAll = true;
        params.subjects = [];
      }
      getTaskStatistics(params).then((res) => {
        const data = res.data.content;
        this.toDayOverTimeCount = data.toDayOverTimeCount;
        this.futureCount = data.futureCount;
        this.overTimeCount = data.overTimeCount;
        this.allCount = data.total;
        this.futureThreeCount = data.futureThreeCount;
        this.futureSevenCount = data.futureSevenCount;
      });
    },
    onChangeType(e) {
      this.nofinishPagination.current = 1;
      this.noFinishFlag = e.target.value;
      this.getTaskTable();
    },
    onChangeFilterTimeStatus(e) {
      this.nofinishPagination.current = 1;
      this.filterTimeStatus = e;
      this.getTaskStatistics();
      this.getTaskTable();
    },

    closeStudentModal() {
      this.visibleAdminStudentInfo = false;
    },
    cancelResign(val) {
      if (val === 'submit') {
        this.showResignModal = false;
        this.approvalFlag = false;
      } else {
        this.showResignModal = false;
      }
    },
    // 创建交接学生
    clickTakeOver() {
      this.approvalActiveIndex = 2;
      this.showResignModal = true;
    },
    queryKnowledgeSummery(id) {
      const params = {
        sectionId: id,
      };
      return queryKnowledgeSummery(params).then((res) => {
        this.$set(this.formInfoData, 'classSection', res.data.content.knowledge);

        this.visibleCourse = true;
      });
    },
    handleGetSelfBookingInfo(studentId, subject) {
      getSelfBookingInfo(studentId, { subject, bookingStatus: 'COMPLETED' }).then(({ data }) => {
        this.$set(this.formInfoData, 'selfBookingInfo', data?.[0]);
      });
    },

    // 新生首通获取课节信息,获取首课信息
    async queryTrailScheduleDetail(processVariables) {
      const studentId = processVariables?.studentId;
      const studentCode = processVariables?.studentCode;
      const subject = processVariables?.student?.subject;
      const createTime = processVariables.processStartTime;

      await Promise.all([
        queryTrailScheduleDetail({
          studentId,
          subject,
        }).then((res) => {
          const data = res?.data?.content;
          if (data.trailScheduleDetail !== null) {
            this.trailCourseScheduleId = data?.trailScheduleDetail?.uuid;
            this.queryCourseSchedule(data?.trailScheduleDetail?.uuid, studentId);
          }
          this.$set(this.formInfoData, 'profileInfo', data);
        }),
        this.handleGetSelfBookingInfo(studentId, subject),
        getFirstLesson({
          studentCode,
          subject,
          createTime,
        }).then((res) => {
          const showScheduleButton = !res.data.currentFirstLessonDate;
          this.$set(this.formInfoData, 'showScheduleButton', showScheduleButton);
        }),
      ]);
    },
    async handleGetHandleCourseList(processVariables, processInstanceId) {
      const { turnManualReason } = processVariables;
      if (!turnManualReason) return;
      const { data } = await getHandleCourseList(processInstanceId);
      this.$set(this.formInfoData, 'handleCourseList', data);
    },
    async queryDaylightReadInfo(processVariables) {
      const { studentScheduleId } = processVariables;
      const { data } = await queryDaylightReadInfoApi(studentScheduleId);

      this.$set(this.formInfoData, 'daylightReadInfo', data);
    },

    cancal(val) {
      if (val === 'submit') {
        this.showApprovalModal = false;
        this.approvalFlag = false;
      } else {
        this.showApprovalModal = false;
      }
    },
    cancelApproval() {
      this.approvalFlag = false;
    },
    addTask() {
      this.approvalFlag = true;
    },
    clickApproval() {
      this.approvalActiveIndex = 1;
      this.showApprovalModal = true;
    },
    // 查询详情里面选择新的授课老师课节
    courseSubScheduleInfo(record) {
      const params = {
        formClassId: record.relatedClassId,
        scheduleStatus: 'NORMAL',
        processInstanceId: record.processInstanceId,
      };
      courseScheduleInfo(params).then((res) => {
        this.courseSubScheduleArr = res.data.content;
      });
    },
    // 获取抽屉里关联任务的详情
    lookSubFeedBack(record) {
      console.log('asdfasdfasdfasdfasdf');
      // 判断如果是长期交接班，老师离职班级交接完成才有确认新的首课课节操作
      if (record.type === 'TAKE_OVER' || record.type === 'CHINESE_RESIGN_TAKE_OVER') {
        this.courseSubScheduleInfo(record);
      }
      this.feedSubBackDetail(record);
    },
    feedSubBackDetail(datas) {
      const params = {
        processInstanceId: datas.processInstanceId,
      };
      feedBackDetail(params).then((res) => {
        this.assigneesList = [];
        const data = res.data.content;
        this.feedBackDeta = data;
        const accountData = this.initRole(res.data.content.taskLogs);
        this.formSubInfoData = {
          taskStatus: datas.taskStatus,
          category: datas.category,
          question: (data.processVariables.depthService ?? '') + data.processVariables.question,
          courseType: this.initOptionsType(data.processVariables.taskType, this.options.courseType),
          processDefinitionName: data.processDefinitionName,
          startUserName: data.processVariables.startUserName,
          userRole: this.initOptionsType(data.processVariables.startUserRole, this.options.userRole),
          startTime: data.startTime,
          dueDate: data.processVariables.dueDate,
          description: data.processVariables.description,
          imgs: data.processVariables.imgs,
          assigneesList: accountData,
          deleted: data.deleted,
          finished: data.finished,
          upComingTypeValues: this.upComingTypeValues,
          showcourseSection: false, // 关联课节不跳转
        };

        // 升级交接班
        if (
          (['TAKE_OVER', 'TAKE_OVER_EN'].includes(datas.type) &&
            datas.processVariables.processType === 'upgrade-take-over') ||
          ['TEACHER_TAKE_OVER_FOLLOW'].includes(datas.type)
        ) {
          const takeOverObj = {
            processType: datas.processType,
            reasonable: data.processVariables.reasonable,
            upgradeFirstCourseDate: data.processVariables.upgradeFirstCourseDate,
            upDescription: data.processVariables.description,
            upgradeProgress: data.processVariables.upgradeProgress,
            firstCourseDate: data.processVariables.firstCourseDate,
            takeOverDate: data.processVariables.takeOverDate,
            classState: data.processVariables.classState,
            className: data.processVariables.className,
            classType:
              data.processVariables.classType === 'PRIVATE' || data.processVariables.classType === '私教课'
                ? '私教课'
                : '班课',
            studyProgress: data.processVariables.studyProgress,
            studentArray: data.processVariables.students,
            relatedClass: data.processVariables.relatedClass,
            takeOverPeriod: data.processVariables.takeOverPeriod,
            outWords: data.processVariables.outWords,
            lastCourseDate: data.processVariables.lastCourseDate,
            courseType: data.processVariables.courseType,
          };

          this.formSubInfoData = { ...this.formSubInfoData, ...takeOverObj };
        }

        // 调整时间交接班
        if (datas.type === 'TAKE_OVER' && datas.processType === 'adjust-time-take-over') {
          const takeOverObj = {
            processType: datas.processType,
            selectDate: data.processVariables.selectDate,
            outProcedure: data.processVariables.outProcedure,
            reason: data.processVariables.reason,
            firstCourseDate: data.processVariables.firstCourseDate,
            nextCourseDate: data.processVariables.nextCourseDate,
            nextCourseContent: data.processVariables.nextCourseContent,
            classState: data.processVariables.classState,
            className: data.processVariables.className,
            classType:
              data.processVariables.classType === 'PRIVATE' || data.processVariables.classType === '私教课'
                ? '私教课'
                : '班课',
            studyProgress: data.processVariables.studyProgress,
            studentArray: data.processVariables.students,
            relatedClass: data.processVariables.relatedClass,
            outWords: data.processVariables.outWords,
            lastCourseDate: data.processVariables.lastCourseDate,
          };
          this.formSubInfoData = { ...this.formSubInfoData, ...takeOverObj };
        }
        // 离职交接班
        if (['TAKE_OVER', 'RESIGN_TAKE_OVER_EN'].includes(datas.type) && datas.processType === 'resign-take-over') {
          const takeOverObj = {
            processType: data.processVariables.processType,
            outProcedure: data.processVariables.outProcedure,
            takeOverDate: data.processVariables.takeOverDate,
            studentArray: data.processVariables.students,
            relatedClass: data.processVariables.relatedClass,
            reasonable: data.processVariables.reasonable,
            workType: data.processVariables.workType,
          };
          this.formSubInfoData = { ...this.formSubInfoData, ...takeOverObj };
        }

        if (datas.type === 'CHINESE_RESIGN_TAKE_OVER') {
          const {
            assignees,
            processType,
            outProcedure,
            takeOverDate,
            relatedClass,
            takeOverPeriod,
            classState,
            reasonable,
            applyUser,
            applyUserRoless,
            relatedTask,
            classType,
            courseType,
          } = data.processVariables;
          this.formSubInfoData = {
            ...this.formSubInfoData,
            assignees,
            processType,
            outProcedure,
            takeOverDate,
            relatedClass,
            takeOverPeriod,
            classState,
            reasonable,
            applyUser,
            applyUserRoless,
            relatedTask,
            classType,
            courseType,
            studentArray: data.processVariables.students,
            resign: true, // 班级离职表单只在教师工作室传入 true,其他false
          };
        }
        // 班主任离职申请
        if (datas.type === 'TASK_CLASS_ADMIN_RESIGN_TAKE_OVER') {
          const takeOverObj = {
            applyUser: data.processVariables.applyUser,
            takeOverDate: data.processVariables.takeOverDate,
            description: data.processVariables.description,
            applyUserRoles: data.processVariables.applyUserRoles,
            outProcedure: data.processVariables.outProcedure,
          };
          this.formSubInfoData = { ...this.formSubInfoData, ...takeOverObj };
        }
        // 原,新班主任交接学生
        if (
          datas.type === 'TASK_RESIGN_STUDENT_OLD_CLASSADMIN' ||
          datas.type === 'TASK_RESIGN_STUDENT_NEW_CLASSADMIN'
        ) {
          const takeOverObj = {
            processType: data.processVariables.processType,
            outProcedure: data.processVariables.outProcedure,
            takeOverDate: data.processVariables.takeOverDate,
            studentArray: data.processVariables.students,
            relatedClass: data.processVariables.relatedClass,
          };
          this.formSubInfoData = { ...this.formSubInfoData, ...takeOverObj };
        }

        if (
          datas.type !== 'CHINESE_TEACHER_LEAVE' &&
          datas.type !== 'TAKE_OVER' &&
          datas.type !== 'CHINESE_RESIGN_TAKE_OVER' &&
          datas.type !== 'APPLICATION_FOR_TEACHER_LEAVE' &&
          datas.type !== 'TASK_CLASS_ADMIN_RESIGN_TAKE_OVER' &&
          datas.type !== 'TASK_CLASS_ADMIN_RESIGN_STUDENT' &&
          datas.type !== 'TASK_CERTIFICATE' &&
          datas.type !== 'TASK_RESIGN_STUDENT_OLD_CLASSADMIN' &&
          datas.type !== 'TASK_RESIGN_STUDENT_NEW_CLASSADMIN' &&
          datas.type !== 'TASK_TERM_VISIT' &&
          datas.type !== 'TASK_WEEK_VISIT' &&
          datas.type !== 'REVIEW_QUESTION_REMINDER' &&
          datas.type !== 'TASK_STUDENT_ACTIVE' &&
          datas.type !== 'TASK_NEXT_FOLLOW_RECORD' &&
          datas.type !== 'TASK_LOSS_REFUND_STUDENT_FIRST_PASS' &&
          datas.type !== 'TASK_STUDENT_CU_EXPIRE_DATE_REMINDER' &&
          datas.type !== 'APPLICATION_FOR_TEACHER_EN' &&
          datas.type !== 'TEACHER_LEAVE_FOLLOW' &&
          datas.type !== 'TAKE_OVER_EN' &&
          datas.type !== 'TEACHER_TAKE_OVER_FOLLOW' &&
          datas.type !== 'RESIGN_TAKE_OVER_EN' &&
          datas.type !== 'TEACHER_RESIGN_TAKE_OVER_FOLLOW' &&
          datas.type !== 'SCHEDULE_FEEDBACK' &&
          datas.type !== 'LOW_SCORE_TEST'
        ) {
          // 请假,班级交接没有关联课节
          this.queryCourseSchedule(data.processVariables.courseScheduleId, data.processVariables.studentId);
        }
        this.visibleSubCourse = true;
      });
    },
    refleshRecord() {
      this.onCloseCourse();
    },
    onCloseSubCourse() {
      this.visibleSubCourse = false;
    },
    openConTaskDeatil(obj) {
      this.subConType = obj.type;
      this.lookSubFeedBack(obj);
    },
    // 查询详情里面选择新的授课老师课节
    courseScheduleInfo() {
      console.log('1adfasdfasdfa');

      const params = {
        formClassId: this.tableListItem.processVariables.relatedClass[0].uuid,
        scheduleStatus: 'NORMAL',
        processInstanceId: this.tableListItem.processInstanceId,
      };
      courseScheduleInfo(params).then((res) => {
        this.courseScheduleArr = res.data.content;
      });
    },
    onSearchReport() {
      // 点击查询清空，清掉地址栏带过来的taskId
      this.queryTaskId = '';
      // 待办
      if (this.activeName === 'nofinish') {
        this.nofinishPagination.current = 1;
        this.getTaskStatistics();
        this.getTaskTable();
      } else if (this.activeName === 'finished') {
        this.finishPagination.current = 1;
        this.getFinishedTableList();
      } else if (this.activeName === 'copyme') {
        this.copyMePagination.current = 1;
        this.getCopyMeTableList();
      } else if (this.activeName === 'createme') {
        this.createMePagination.current = 1;
        this.getCreateMeTableList();
      }
    },
    onMakeUpHandle(data) {
      window.open(
        `${process.env.VUE_APP_MAKEUP_URL}?studentCode=${data.studentArray[0].code}
        &businessId=${this.feedBackDeta.processInstanceId}
        &merchantId=2d6e71ae-c0d6-40b2-9633-48e7dd9d1cea&token=${_token}
        &username=${this.feedBackDeta.processVariables.startUserName}
        &email=${this.userInfo.email}
        `,
      );
    },
    onOrder(data) {
      this.$store.commit('setStudentName', data.studnetData.name);
      this.$store.commit('setStudentIdRoute', data.studnetData.id);
      session.set('studentProfile', {
        code: data.studnetData.code,
        fullName: data.studnetData.name,
        timezone: data.studnetData.timezone,
        timezoneStd: data.studnetData.timezoneStd,
        uuid: data.studnetData.id,
      });
      // window.open(
      //   `${process.env.VUE_APP_MAKEUP_URL}?studentCode=${data.students[0].code}&businessId=57526073-055c-4f58-adb0-ba555ea9c8b2&merchantId=2d6e71ae-c0d6-40b2-9633-48e7dd9d1cea&token=${_token}&username=${this.userInfo.fullName}&email=${this.userInfo.email}&orderId=${this.feedBackDeta.processVariables.orderInfo.orderId}&origin=classTeacher`,
      // );
      this.$router.push({ path: '/order', query: { id: data.formInfoData.orderInfo.orderId } });
    },
    // 完成补升
    completeMakeUp() {
      this.refreshTaskTable();
    },
    cancelMakeUp() {
      this.finishMakeUpFlag = false;
    },
    // 将base64的图片转换为file文件
    convertBase64UrlToBlob(urlData) {
      const bytes = window.atob(urlData.split(',')[1]); // 去掉url的头，并转换为byte
      // 处理异常,将ascii码小于0的转换为大于0
      const ab = new ArrayBuffer(bytes.length);
      const ia = new Uint8Array(ab);
      for (let i = 0; i < bytes.length; i += 1) {
        ia[i] = bytes.charCodeAt(i);
      }
      return new Blob([ab], { type: 'image/jpeg' });
    },
    // 将blob转换为file
    blobToFile(theBlob, fileName) {
      return new File([theBlob], fileName, { type: 'image/jpeg', lastModified: Date.now() });
    },
    sendStudyReport(formData) {
      const blob = this.convertBase64UrlToBlob(formData.imgData);
      const file = this.blobToFile(blob, 'studyReport');
      getUploadOssToken().then((res) => {
        this.uploadToOss(file, res.data.content, formData);
      });
    },
    uploadToOss(info, fileData, oldFormData) {
      const formData = new FormData();
      const formArray = Object.entries(fileData.formFields);
      formArray.forEach((item) => {
        formData.append(item[0], item[1]);
      });
      formData.append('file', info);
      imgpostUrl(`https://${fileData.endpoint}`, formData).then((res) => {
        const newFormData = oldFormData;
        newFormData.imgData = res.data.url;
        this.sendStudyReportGroup(newFormData);
      });
    },
    // 发送学习报告到群里
    sendStudyReportGroup(data) {
      const params = {
        studentId: data.studentId,
        imgData: data.imgData,
        subject: this.reportSubject,
      };
      sendPosterGroup(params).then(() => {
        this.$message.success('发送成功');
      });
    },
    // 点击详情学生
    onGetStudentId(item) {
      this.$store.commit('setStudentName', item.name);
      this.$store.commit('setStudentIdRoute', item.id || item.studentId);
      // this.$router.push({ path: '/myStudent' });
      const routeUrl = this.$router.resolve({
        path: '/studentDetail',
        query: { uuid: item.id },
      });
      window.open(routeUrl.href, '_blank');
    },
    // 点击线索名称跳转线索详情
    onHrefClue(item) {
      const routeUrl = this.$router.resolve({
        path: '/studentDetail',
        query: { uuid: item.id, type: 'clue', studentCode: item.code },
      });
      window.open(routeUrl.href, '_blank');
    },
    // 点击表格页码请求数据
    handleTableChange(pagination, filters, sorter) {
      // 完成详情里面得完成后刷新对应tab下得表格数据
      switch (this.activeName) {
        case 'nofinish':
          this.nofinishPagination = pagination;
          this.noFinishSorter = sorter;
          this.getTaskTable();
          break;
        case 'finished':
          this.finishPagination = pagination;
          this.finishSorter = sorter;
          this.getFinishedTableList();
          break;
        case 'copyme':
          this.copyMePagination = pagination;
          this.copymeSorter = sorter;
          this.getCopyMeTableList();
          break;
        case 'createme':
          this.createMePagination = pagination;
          this.createSorter = sorter;
          this.getCreateMeTableList();
          break;
        default:
      }
    },
    // 点击刷新表格列表
    refreshTaskTable() {
      // 完成详情里面得完成后刷新对应tab下得表格数据
      switch (this.activeName) {
        case 'nofinish':
          this.getTaskTable();
          break;
        case 'finished':
          this.getFinishedTableList();
          break;
        case 'copyme':
          this.getCopyMeTableList();
          break;
        case 'createme':
          this.getCreateMeTableList();
          break;
        default:
      }
    },
    // 获取我创建的
    getCreateMeTableList() {
      this.tableCreateLoading = true;
      const params = {
        classAdminId: this.userInfo.userID,
        assigneeId: this.assigneeId,
        deptId: this.deptId,
        categorySet: this.taskTypeName,
        subjects: this.getSearchSubjects(),
        currentPage: this.createMePagination.current,
        pageSize: this.createMePagination.pageSize,
        studentCondition: this.studentCondition,
        taskStatus: this.taskStatus,
        taskName: this.taskName,
        depthService: this.depthService,
      };
      params.orderBy = this.createSorter.columnKey;
      if (this.createSorter.order === 'descend') {
        params.sort = 'desc';
      } else if (this.createSorter.order === 'ascend') {
        params.sort = 'asc';
      }
      createMeTableList(params).then((res) => {
        this.tableCreateLoading = false;
        this.tableCreateMeData = res.data.content.content;

        this.createMePagination.total = res.data.content.totalCount;
      });
    },
    getCopyMeTableList() {
      this.tableCopyLoading = true;
      const params = {
        classAdminId: this.userInfo.userID,
        deptId: this.deptId,
        assigneeId: this.assigneeId,
        categorySet: this.taskTypeName,
        subjects: this.getSearchSubjects(),
        currentPage: this.copyMePagination.current,
        pageSize: this.copyMePagination.pageSize,
        studentCondition: this.studentCondition,
        taskStatus: this.taskStatus,
        taskName: this.taskName,
        depthService: this.depthService,
      };
      params.orderBy = this.copymeSorter.columnKey;
      if (this.copymeSorter.order === 'descend') {
        params.sort = 'desc';
      } else if (this.copymeSorter.order === 'ascend') {
        params.sort = 'asc';
      }
      copyMeTableList(params).then((res) => {
        this.tableCopyLoading = false;
        this.tableCopyMeData = res.data.content.content;

        this.copyMePagination.total = res.data.content.totalCount;
      });
    },
    // 控制详情里面操作弹窗得开关
    initOperateVisible(val) {
      this.operatevisibleWrap = val;
    },
    // 查询课节
    queryCourseSchedule(uuid, studentId) {
      const params = {
        uuid,
      };

      studentId && (params.studentId = studentId);

      queryCourseSchedule(params).then((res) => {
        const resCourseInfo = res.data.content;
        this.reportSubject = resCourseInfo.courseSectionRespDTO.course.subject;
        this.liveUrl = res.data.content.liveUrl; // 监课的视频地址
        let studentName = '';
        if (resCourseInfo.students.length > 0) {
          studentName =
            resCourseInfo.students.length > 1
              ? `${resCourseInfo.students.length}人`
              : resCourseInfo.students[0].fullName;
        }
        const studentArray = resCourseInfo.students.filter((item) => item.id === studentId);

        const courseSectionDataStr = `${resCourseInfo.courseSectionRespDTO.data.level1 || ''}-${
          resCourseInfo.courseSectionRespDTO.data.level2 || ''
        }-${resCourseInfo.courseSectionRespDTO.data.level3 || ''}`;
        const courseInfo = {
          courseName: resCourseInfo.courseSectionRespDTO.data.level3
            ? `第${resCourseInfo.courseSectionRespDTO.data.level3}课- `
            : '',
          scheduleStatus: res.data.content.scheduleStatus,
          platform: res.data.content.platform,

          courseSectionName: courseSectionDataStr,
          scheduleName: res.data.content.scheduleName,
          teacherName: res.data.content.teacher.fullName,
          uuid: res.data.content.uuid,
          classType: res.data.content.classType,
          studentName,
          studentArray,
          formClassId: res.data.content.formClassId,
          formClassName: res.data.content.formClassName,
          startTime: `${this.$moment(res.data.content.startDateTime).format(
            'YYYY年MM月DD日 HH:mm',
          )}-${res.data.content.endDateTime.substring(11)}`,
          startStudentTime: `${this.$moment(resCourseInfo?.startStudentDateTime).format(
            'YYYY年MM月DD日 HH:mm',
          )}-${resCourseInfo?.endStudentDateTime?.substring(11)}`,
        };

        this.$set(this.formInfoData, 'courseInfo', courseInfo);
        if (this.taskInfoDataType !== 'STAGE_LEARNING_REPORT') {
          this.visibleCourse = true;
        }
      });
    },
    initRole(datas) {
      return datas.map((item) => {
        let accounts = null;
        let taskFromAccount = null;
        if (item.accounts) {
          accounts = item.accounts.map((i) => {
            const rolesC = [];
            if (i && i.roles) {
              i.roles.forEach((k) => {
                rolesC.push(this.initOptionsType(k, this.options.userRole));
              });
            }
            return {
              ...i,
              rolesC: rolesC.join('/'),
            };
          });
        }
        if (item.taskFromAccount) {
          taskFromAccount = item.taskFromAccount;
          const taskFromAccountRoles = [];
          if (taskFromAccount.roles) {
            taskFromAccount.roles.forEach((k) => {
              taskFromAccountRoles.push(this.initOptionsType(k, this.options.userRole));
            });
          }
          taskFromAccount.rolesC = taskFromAccountRoles.join('/');
        }
        return {
          ...item,
          taskFromAccount,
          accounts,
        };
      });
    },
    // 获取抽屉里反馈详情
    feedBackDetail(datas) {
      const params = {
        processInstanceId: datas.processInstanceId,
        taskId: datas.id,
      };
      feedBackDetail(params).then(async (res) => {
        this.assigneesList = [];
        const data = res.data.content;
        this.feedBackDeta = data;
        const accountData = this.initRole(res.data.content.taskLogs);
        this.formInfoData = {
          taskDefinitionKey: datas.taskDefinitionKey,
          subjectType: datas.subjectType,
          processInstanceId: datas.processInstanceId,
          taskStatus: datas.taskStatus,
          category: datas.category,
          question: (data.processVariables.depthService ?? '') + datas.processVariables.question,
          courseType: this.initOptionsType(data.processVariables.taskType, this.options.courseType),
          processDefinitionName: data.name,
          startUserName: data.processVariables.startUserName,
          userRole: this.initOptionsType(data.processVariables.startUserRole, this.options.userRole),
          startTime: data.startTime,
          dueDate: data.processVariables.dueDate,
          description: data.processVariables.description,
          imgs: data.processVariables.imgs,
          assigneesList: accountData,
          deleted: data.deleted,
          finished: data.finished,
          subDetailType: 'classAdmin',
          teacherId: data.processVariables.teacherId,
          upComingTypeValues: this.upComingTypeValues,
          showcourseSection: false, // 关联课节不跳转
          subject: data.processVariables.subject,
          teacherName: data.processVariables.teacherName,
          adjustTarget: data.processVariables.adjustTarget, // 调整进度目标
        };
        console.log('datas', datas);
        // 挽单
        if (datas.type === 'ATTEMPT_RETENTION_ON_CANCELLATION') {
          const obj = {
            studentArray: [data.processVariables.student],
            reason: data.processVariables.reason,
            remark: data.processVariables.remark,
            subscribeId: data.processVariables.subscribeId,
            studentId: data.processVariables.studentId,
          };
          this.formInfoData = { ...this.formInfoData, ...obj };
        }
        // 竞赛课班主任通知
        if (datas.type === 'TASK_TOPIC_COURSE_SELECTION_FOLLOW_UP') {
          const obj = {
            studentArray: [data.processVariables.student],
            // relatedClass: data.processVariables.relatedClass,
            // changeDateChinaZone: data.processVariables.changeDateChinaZone,
            // changeDateStudentZone: data.processVariables.changeDateStudentZone,
            // studentOldTimeZone: data.processVariables.studentOldTimeZone,
            // studentNewTimeZone: data.processVariables.studentNewTimeZone,
            // formClassChangeSeconds: data.processVariables.formClassChangeSeconds,
            // studentChangeSeconds: data.processVariables.studentChangeSeconds,
            // formClassOldTimeZone: data.processVariables.formClassOldTimeZone,
            // formClassNewTimeZone: data.processVariables.formClassNewTimeZone,
            // studentStartTime: `${this.$moment(data.processVariables.startDateTimeStudentZone).format(
            //   'YYYY年MM月DD日 HH:mm',
            // )}-${data.processVariables.endDateTimeStudentZone.substring(11, 16)}`,
          };
          this.formInfoData = { ...this.formInfoData, ...obj };
        }
        // 时令时区变化
        if (datas.type === 'DAYLIGHT_TIME_CHANGED') {
          const obj = {
            studentArray: [data.processVariables.student],
            relatedClass: data.processVariables.relatedClass,
            changeDateChinaZone: data.processVariables.changeDateChinaZone,
            changeDateStudentZone: data.processVariables.changeDateStudentZone,
            studentOldTimeZone: data.processVariables.studentOldTimeZone,
            studentNewTimeZone: data.processVariables.studentNewTimeZone,
            formClassChangeSeconds: data.processVariables.formClassChangeSeconds,
            studentChangeSeconds: data.processVariables.studentChangeSeconds,
            formClassOldTimeZone: data.processVariables.formClassOldTimeZone,
            formClassNewTimeZone: data.processVariables.formClassNewTimeZone,
            studentStartTime: `${this.$moment(data.processVariables.startDateTimeStudentZone).format(
              'YYYY年MM月DD日 HH:mm',
            )}-${data.processVariables.endDateTimeStudentZone.substring(11, 16)}`,
            studentScheduleId: data.processVariables.studentScheduleId,
          };
          this.formInfoData = { ...this.formInfoData, ...obj };
        }
        // 约课任务，线索创建
        if (datas.type === 'TASK_SUBSCRIBE_COURSE' || datas.type === 'TASK_TRACK_CREATE') {
          const obj = {
            studentArray: [data.processVariables.student],
          };
          this.formInfoData = { ...this.formInfoData, ...obj };
        }
        // 不续费
        if (datas.type === 'TASK_NOT_RENEW') {
          const obj = {
            showStudyPlan: data.processVariables.showStudyPlan,
            studentArray: [data.processVariables.student],
            showButton: datas.processVariables.showButton,
            processInstanceId: data.processInstanceId,
            subjectName: data.processVariables.subjectName,
            studentName: data.processVariables.studentName,
            studentCode: data.processVariables.studentCode,
            userRole: datas.processVariables.userRole,
          };
          this.formInfoData = { ...this.formInfoData, ...obj };
        }
        // 赠课审批
        if (datas.type === 'TASK_STUDENT_SHARE_AUDIT') {
          const obj = {
            subjectName: data.processVariables.subjectName,
            studentArray: data.processVariables.students,
            imgs: data.processVariables.url,
            courseUnit: data.processVariables.courseUnit,
            // remark:data.processVariables.remark
          };
          this.formInfoData = { ...this.formInfoData, ...obj };
        }
        // 请假相关
        if (['CHINESE_TEACHER_LEAVE', 'APPLICATION_FOR_TEACHER_EN', 'TEACHER_LEAVE_FOLLOW'].includes(datas.type)) {
          const {
            leaveStartTime,
            leaveEndTime,
            relatedClass,
            applyUser,
            applyUserRoles,
            subject,
            student,
            reasonType,
            reason,
          } = data.processVariables;
          this.formInfoData = {
            ...this.formInfoData,
            leaveStartTime,
            leaveEndTime,
            relatedClass,
            applyUser,
            applyUserRoles,
            subject,
            reasonType,
            reason,

            studentArray: student ? [student] : [],
          };
        }
        // 老师交接班后首课回访
        if (datas.type === 'TASK_CHANGE_TEACHER_VISIT') {
          const takeOverObj = {
            processType: data.processVariables.processType,
            outProcedure: data.processVariables.outProcedure,
            takeOverDate: data.processVariables.takeOverDate,
            studentArray: [data.processVariables.student],
            relatedClass: data.processVariables.relatedClass,
            relatedTask: data.processVariables.relatedTask,
          };
          this.formInfoData = { ...this.formInfoData, ...takeOverObj };
        }
        // 长期交接后首课回访
        if (datas.type === 'TASK_LONG_TASK_OVER_VISIT') {
          const takeOverObj = {
            processType: data.processVariables.processType,
            outProcedure: data.processVariables.outProcedure,
            takeOverDate: data.processVariables.takeOverDate,
            studentArray: [data.processVariables.student],
            relatedClass: data.processVariables.relatedClass,
            reason: data.processVariables.reason,
            remark: data.processVariables.remark,
          };
          this.formInfoData = { ...this.formInfoData, ...takeOverObj };
        }
        // 订单寄送确认
        if (datas.type === 'TASK_CONFIRM_ORDER') {
          // const id = '50e3dc5c-03d9-49cf-9fd6-7d93aaeba653';
          const orderIdParams = {
            orderId: datas.processVariables.orderId,
          };
          try {
            const studentInfo = await queryStudentDetailData({ studentId: data.processVariables.studentId });
            this.receiverProfile = {
              ...studentInfo.data.content.data,
              name: studentInfo.data.content.data.recipientName,
              phoneNumber: studentInfo.data.content.data.recipientPhone,
              postCode: studentInfo.data.content.data.address.postalCode,
            };
          } catch (error) {
            console.log(error);
          }

          orderInfo(orderIdParams).then(async (res) => {
            let orderStatus = '';
            switch (res.data.orderStatus) {
              case 'PENDING_PAYMENT':
                orderStatus = '待支付';
                break;
              case 'PAYMENT_RECEIVED':
                orderStatus = '已支付';
                break;
              case 'CONFIRMED':
                orderStatus = '已支付待确认';
                break;
              default:
                break;
            }
            let logisticsStatus = '';
            switch (res.data.orderData.logisticsStatus) {
              case 'NO_NEED_TO_SHIP':
                logisticsStatus = '无需发货';
                break;
              case 'PENDING_CONFIRM':
                logisticsStatus = '待确认';
                break;
              case 'PENDING':
                logisticsStatus = '待发货';
                break;
              case 'SENT':
                logisticsStatus = '已发货';
                break;
              case 'CANCEL':
                logisticsStatus = '已取消';
                break;
              default:
                break;
            }
            const takeOverObj = {
              processType: data.processVariables.processType,
              outProcedure: data.processVariables.outProcedure,
              takeOverDate: data.processVariables.takeOverDate,
              studentArray: [data.processVariables.student],
              trackOrder: data.processVariables.trackOrder,
              studentName: data.processVariables.studentName,
              studentCode: data.processVariables.studentCode,
              studentId: data.processVariables.studentId,
              whenCreated: res.data.whenCreated,
              orderStatus,
              logisticsStatus,
              orderId: datas.processVariables.orderId,
              handleParams: params,
              receiverProfile: this.receiverProfile,
            };
            this.formInfoData = { ...this.formInfoData, ...takeOverObj };
          });
        }
        // 升级交接班
        if (
          (['TAKE_OVER', 'TAKE_OVER_EN'].includes(datas.type) &&
            datas.processVariables.processType === 'upgrade-take-over') ||
          ['TEACHER_TAKE_OVER_FOLLOW'].includes(datas.type)
        ) {
          const takeOverObj = {
            processType: datas.processVariables.processType,
            reasonable: data.processVariables.reasonable,
            upgradeFirstCourseDate: data.processVariables.upgradeFirstCourseDate,
            upDescription: data.processVariables.description,
            upgradeProgress: data.processVariables.upgradeProgress,
            firstCourseDate: data.processVariables.firstCourseDate,
            takeOverDate: data.processVariables.takeOverDate,
            classState: data.processVariables.classState,
            className: data.processVariables.className,
            classType:
              data.processVariables.classType === 'PRIVATE' || data.processVariables.classType === '私教课'
                ? '私教课'
                : '班课',
            studyProgress: data.processVariables.studyProgress,
            studentArray: data.processVariables.students,
            relatedClass: data.processVariables.relatedClass,
            takeOverPeriod: data.processVariables.takeOverPeriod,
            outWords: data.processVariables.outWords,
            lastCourseDate: data.processVariables.lastCourseDate,
            processInstanceId: data.processInstanceId,
            adjustScheduleProcess: data?.processVariables?.adjustScheduleProcess,
            taskId: datas.id,
            subject: data.processVariables.subject,
            outProcedure: data.processVariables.outProcedure,
            firstSchedule: data.processVariables.firstSchedule,
            teacherName: data.processVariables.teacherName,
            startUserName: data.processVariables.startUserName,
            newSchedule: data.processVariables.newSchedule,
          };

          this.formInfoData = { ...this.formInfoData, ...takeOverObj };
        }

        // 调整时间交接班
        if (datas.type === 'TAKE_OVER' && datas.processVariables.processType === 'adjust-time-take-over') {
          const takeOverObj = {
            processType: datas.processVariables.processType,
            selectDate: data.processVariables.selectDate,
            outProcedure: data.processVariables.outProcedure,
            reason: data.processVariables.reason,
            firstCourseDate: data.processVariables.firstCourseDate,
            nextCourseDate: data.processVariables.nextCourseDate,
            nextCourseContent: data.processVariables.nextCourseContent,
            classState: data.processVariables.classState,
            className: data.processVariables.className,
            classType:
              data.processVariables.classType === 'PRIVATE' || data.processVariables.classType === '私教课'
                ? '私教课'
                : '班课',
            studyProgress: data.processVariables.studyProgress,
            studentArray: data.processVariables.students,
            relatedClass: data.processVariables.relatedClass,
            lastCourseDate: data.processVariables.lastCourseDate,
          };
          this.formInfoData = { ...this.formInfoData, ...takeOverObj };
        }
        // 离职交接班
        if (['TAKE_OVER'].includes(datas.type) && data.processVariables.processType === 'resign-take-over') {
          const takeOverObj = {
            processType: data.processVariables.processType,
            outProcedure: data.processVariables.outProcedure,
            takeOverDate: data.processVariables.takeOverDate,
            studentArray: data.processVariables.students,
            relatedClass: data.processVariables.relatedClass,
          };
          this.formInfoData = { ...this.formInfoData, ...takeOverObj };
        }
        // 离职交接班及跟进
        if (
          ['TEACHER_RESIGN_TAKE_OVER_FOLLOW', 'CHINESE_RESIGN_TAKE_OVER'].includes(datas.type) ||
          (['RESIGN_TAKE_OVER_EN'].includes(datas.type) && data.processVariables.processType === 'resign-take-over')
        ) {
          const {
            assignees,
            processType,
            outProcedure,
            takeOverDate,
            relatedClass,
            takeOverPeriod,
            classState,
            reasonable,
            applyUser,
            teacherName,
            applyUserRoless,
            relatedTask,
            classType,
            courseType,
            workType,
            startUserName,
            userRole,
            newSchedule,
          } = data.processVariables;

          this.formInfoData = {
            ...this.formInfoData,
            assignees,
            processType,
            outProcedure,
            takeOverDate,
            relatedClass,
            takeOverPeriod,
            classState,
            reasonable,
            applyUser,
            teacherName,
            applyUserRoless,
            relatedTask,
            classType,
            courseType,
            workType,
            startUserName,
            userRole,
            newSchedule,
            processInstanceId: data.processInstanceId,
            adjustScheduleProcess: data?.processVariables?.adjustScheduleProcess,
            taskId: datas.id,
            subject: data.processVariables.subject,
            studentArray: data.processVariables.students,
            resign: true, // 班级离职表单只在教师工作室传入 true,其他false
          };
        }
        // 学习报告
        if (datas.type === 'STAGE_LEARNING_REPORT') {
          const takeOverObj = {
            reportPhase: data.processVariables.reportPhase,
            studyProgress: data.processVariables.studyProgress,
            studentArray: data.processVariables.students,
            relatedClass: data.processVariables.relatedClass,
            processType: data.processVariables.processType,
            score: data.processVariables.score,
            listeningLevel: data.processVariables.listeningLevel,
            readingLevel: data.processVariables.readingLevel,
            speakingLevel: data.processVariables.speakingLevel,
            writingLevel: data.processVariables.writingLevel,
            remake: data.processVariables.remake,
            imgUrl: data.processVariables.imgData,
            progress: data.processVariables.progress,
            teacherName: data.processVariables.teacherName,
            imgData: data.processVariables.imgData,
            courseType: data.processVariables.courseType,
            classType:
              data.processVariables.classType === 'PRIVATE' || data.processVariables.classType === '私教课'
                ? '私教课'
                : '班课',
            createTime: this.$moment(data.processVariables.createTime).format('YYYY年MM月DD日 HH:mm:ss'),
            showNewReport: data.processVariables.newStudy,
          };

          this.formInfoData = { ...this.formInfoData, ...takeOverObj };
          console.log(this.formInfoData);
        }
        // 请假代课申请
        if (datas.type === 'APPLICATION_FOR_TEACHER_LEAVE') {
          const {
            leaveStartTime,
            leaveEndTime,
            classType,
            relatedClass,
            applyUser,
            applyUserRoles,
            adjustScheduleProcess,
            reasonType,
            reason,
            turnManualReason,
          } = data.processVariables;
          this.formInfoData = {
            ...this.formInfoData,
            reasonType,
            reason,
            leaveStartTime,
            leaveEndTime,
            classType,
            relatedClass,
            applyUser,
            applyUserRoles,
            adjustScheduleProcess,
            turnManualReason,
            taskId: this.operateTaskId,
          };
        }
        // 课后反馈
        if (datas.type === 'SCHEDULE_FEEDBACK') {
          const {
            studentName,
            courseScheduleId,
            courseScheduleName,
            courseScheduleStartDateTime,
            startUserName,
            teachingFeedbackCategory,
            reasonList,
            cuReturn,
            cuReturnNum,
            approveNumber,
            remark,
            picture,
            student,
            studentCode,
          } = data.processVariables;
          console.log(data.processVariables);
          this.formInfoData = {
            ...this.formInfoData,
            studentName,
            courseScheduleId,
            courseScheduleName,
            courseScheduleStartDateTime,
            startUserName,
            teachingFeedbackCategory,
            reasonList,
            cuReturn,
            cuReturnNum,
            approveNumber,
            remark,
            picture,
            studentCode,
            studentArray: [student],
          };
        }

        // 班主任离职申请
        if (datas.type === 'TASK_CLASS_ADMIN_RESIGN_TAKE_OVER') {
          const takeOverObj = {
            applyUser: data.processVariables.applyUser,
            takeOverDate: data.processVariables.takeOverDate,
            description: data.processVariables.description,
            applyUserRoles: data.processVariables.applyUserRoles,
            outProcedure: data.processVariables.outProcedure,
          };
          this.formInfoData = { ...this.formInfoData, ...takeOverObj };
        }
        // 低分测试
        if (datas.type === 'LOW_SCORE_TEST') {
          this.formInfoData = {
            ...this.formInfoData,
            studentArray: [
              {
                name: data.processVariables.studentName,
                code: data.processVariables.studentCode,
                id: data.processVariables.studentId,
              },
            ],
          };
        }

        // 重复线索
        if (datas.type === 'TASK_REPEAT_TRACK') {
          const { source, subjectName, trackCreateTime } = data.processVariables;
          const studentArray = [
            {
              name: data.processVariables.studentName,
              code: data.processVariables.studentCode,
            },
          ];
          this.formInfoData = {
            ...this.formInfoData,
            source,
            subjectName,
            trackCreateTime,
            studentArray,
          };
        }
        // 补升订单审批
        if (datas.type === 'PROMOTE_EXPIRED_TASK') {
          const { subjectName, reason, firstCourseDate, trackCreateTime } = data.processVariables;
          const studentArray = [
            {
              name: data.processVariables.studentName,
              code: data.processVariables.studentCode,
            },
          ];
          const relatedTask = {
            name: data.processVariables.relatedProcessName,
          };
          if (!this.formInfoData.category) {
            this.formInfoData.category = data?.name;
          }
          this.formInfoData = {
            ...this.formInfoData,
            subjectName,
            reason,
            firstCourseDate,
            trackCreateTime,
            studentArray,
            relatedTask,
          };
        }
        // 班主任离职交接学生申请
        if (datas.type === 'TASK_CLASS_ADMIN_RESIGN_STUDENT') {
          const takeOverObj = {
            student: data.processVariables.student,
            relatedTask: data.processVariables.relatedTask,
            classAdmin: data.processVariables.classAdmin,
          };
          this.formInfoData = { ...this.formInfoData, ...takeOverObj };
        }
        // 学员跟进
        if (datas.type === 'TASK_NEXT_FOLLOW_RECORD') {
          const takeOverObj = {
            student: data.processVariables.student,
            relatedTask: data.processVariables.relatedTask,
            followDesc: data.processVariables.nextFollowDesc,
          };
          this.formInfoData = { ...this.formInfoData, ...takeOverObj };
        }
        // 流失学员首通
        if (datas.type === 'TASK_LOSS_REFUND_STUDENT_FIRST_PASS') {
          const takeOverObj = {
            student: data.processVariables.student,
            lossRefundId: data.processVariables.lossRefundId,
          };
          this.formInfoData = { ...this.formInfoData, ...takeOverObj };
        }
        // 课时有效期

        if (datas.type === 'TASK_STUDENT_CU_EXPIRE_DATE_REMINDER') {
          const takeOverObj = {
            student: data.processVariables.student,
            classHourDetail: data.processVariables.detail,
            totalCuNum: data.processVariables.totalCuNum,
            totalQuotaNum: data.processVariables.totalQuotaNum,
          };
          this.formInfoData = { ...this.formInfoData, ...takeOverObj };
        }
        // 结业证书
        if (datas.type === 'TASK_CERTIFICATE') {
          const takeOverObj = {
            sectionName: data.processVariables.courseSchedule.courseSectionRespDTO.sectionName,
            studentArray: [data.processVariables.student],
            formClassName: data.processVariables.courseSchedule.formClassName,
            teacherName: data.processVariables.teacherName,
            courseType: data.processVariables.courseType,
            classType:
              data.processVariables.classType === 'PRIVATE' || data.processVariables.classType === '私教课'
                ? '私教课'
                : '班课',
            createTime: this.$moment(data.processVariables.createTime).format('YYYY年MM月DD日 HH:mm:ss'),
            reportData: data.processVariables.reportData,
            subjectName: data.processVariables.subjectName,
          };
          this.formInfoData = { ...this.formInfoData, ...takeOverObj };
        }
        // 数学订单审批
        if (datas.type === 'SUBMIT_ORDER_TASK') {
          const { orderInfo, student, subjectName } = data.processVariables;
          this.formInfoData = { ...this.formInfoData, orderInfo, students: [student], subjectName };
        }
        // 学员缺勤
        if (datas.type === 'TASK_STUDENT_ABSENT') {
          const takeOverObj = {
            studentArray: [data.processVariables.student],
            absentCourseInfo: { ...data.processVariables.schedule },
          };
          this.formInfoData = { ...this.formInfoData, ...takeOverObj };
        }
        // 原,新班主任交接学生

        if (
          datas.type === 'TASK_RESIGN_STUDENT_OLD_CLASSADMIN' ||
          datas.type === 'TASK_RESIGN_STUDENT_NEW_CLASSADMIN'
        ) {
          const takeOverObj = {
            processType: data.processVariables.processType,
            outProcedure: data.processVariables.outProcedure,
            takeOverDate: data.processVariables.takeOverDate,
            studentArray: [data.processVariables.student],
            takeOverType: data.processVariables.takeOverType,
            takeOverTime: `${data.processVariables.takeOverStartTime} 至 ${data.processVariables.takeOverEndTime}`,
          };
          this.formInfoData = { ...this.formInfoData, ...takeOverObj };
        }
        // 授课老师旷课,数学阶段回访任务,数学周回访任务

        if (
          datas.type === 'TASK_TEACHER_ABSENT' ||
          datas.type === 'TASK_TERM_VISIT' ||
          datas.type === 'TASK_WEEK_VISIT' ||
          datas.type === 'REVIEW_QUESTION_REMINDER' ||
          datas.type === 'TEACHER_LATE'
        ) {
          const takeOverObj = {
            studentArray: [data.processVariables.student],
          };
          this.formInfoData = { ...this.formInfoData, ...takeOverObj };
        }
        // 学员激活任务

        if (datas.type === 'TASK_STUDENT_ACTIVE') {
          const takeOverObj = {
            subjectName: datas.processVariables.subjectName,
            studentStatus: datas.processVariables.studentStatus,
            freezeToDate: datas.processVariables.freezeToDate,
            freezeFromDate: datas.processVariables.freezeFromDate,
            studentArray: [data.processVariables.student],
          };
          this.formInfoData = { ...this.formInfoData, ...takeOverObj };
        }
        // 新生首通，排课等详情,班主任交接学员首通
        if (
          datas.type === 'TASK_FIRST_PASS' ||
          datas.type === 'TASK_FIRST_FOLLOW_UP' ||
          datas.type === 'TASK_CREATE_GROUP' ||
          datas.type === 'NEW_STUDENT_FOLLOW_UP' ||
          datas.type === 'TASK_SCHEDULE' ||
          datas.type === 'TASK_PROMOTE'
        ) {
          const takeOverObj = {
            studentArray: [data.processVariables.student],
            courseConsultantName: data.processVariables.student.courseConsultantName,
            dueDate: this.tableListItem.processVariables.dueDate,
            expireFlag: this.tableListItem.processVariables.expireFlag,
            showStudyPlan: data.processVariables.showStudyPlan,
            subjectName: data.processVariables.subjectName,
          };

          this.formInfoData = { ...this.formInfoData, ...takeOverObj };
        }
        // 班主任交接学员首通
        // 交接学生跟进
        if (
          ['TRANSFER_STUDENT_FOLLOW_UP', 'TASK_CLASS_ADMIN_FIRST_PASS', 'TASK_CLASS_ADMIN_JOIN'].includes(datas.type)
        ) {
          const takeOverObj = {
            studentArray: [data.processVariables.student],
            preClassAdmin: data.processVariables.preClassAdmin,
            dueDate: this.tableListItem.processVariables.dueDate,
            expireFlag: this.tableListItem.processVariables.expireFlag,
          };
          this.formInfoData = { ...this.formInfoData, ...takeOverObj };
        }
        // 首课回访
        if (
          datas.type === 'TASK_FIRST_VISIT' ||
          datas.type === 'TASK_MOTH_VISIT' ||
          datas.type === 'TASK_W2_VISIT' ||
          datas.type === 'TASK_W3_VISIT' ||
          datas.type === 'TASK_MOTH_STUDY_VISIT'
        ) {
          const takeOverObj = {
            studentArray: [data.processVariables.student],
            dueDate: this.tableListItem.processVariables.dueDate,
            expireFlag: this.tableListItem.processVariables.expireFlag,
          };
          this.formInfoData = { ...this.formInfoData, ...takeOverObj };
        }
        // 私教课加课
        if (datas.type === 'TASK_PRIVATE_CLASS_REMINDER') {
          const takeOverObj = {
            classType:
              data.processVariables.classType === 'PRIVATE' || data.processVariables.classType === '私教课'
                ? '私教课'
                : '班课',
            relatedClass: data.processVariables.relatedClass,
          };
          this.formInfoData = { ...this.formInfoData, ...takeOverObj };
        }
        // 多哇任务
        if (datas.type === 'TASK_STUDENT_MULTI_CHILD') {
          const obj = {
            studentArray: [data.processVariables.student],
            processInstanceId: data.processInstanceId,
          };
          this.formInfoData = { ...this.formInfoData, ...obj };
        }

        // 查询学生其他任务与家庭其他任务
        if (datas?.processVariables?.studentCode) {
          queryStudentOtherTask({
            studentId: datas?.processVariables?.studentId || datas?.processVariables?.student?.studentId,
            currentTaskId: datas.id,
          }).then((otherTaskRes) => {
            this.formInfoData = { ...this.formInfoData, ...otherTaskRes.data.content };
          });
        }

        if (
          datas.type !== 'CHINESE_TEACHER_LEAVE' &&
          datas.type !== 'TAKE_OVER' &&
          datas.type !== 'CHINESE_RESIGN_TAKE_OVER' &&
          datas.type !== 'APPLICATION_FOR_TEACHER_LEAVE' &&
          datas.type !== 'TASK_CLASS_ADMIN_RESIGN_TAKE_OVER' &&
          datas.type !== 'TASK_CLASS_ADMIN_RESIGN_STUDENT' &&
          datas.type !== 'TASK_REPEAT_TRACK' &&
          datas.type !== 'PROMOTE_EXPIRED_TASK' &&
          datas.type !== 'TASK_CONFIRM_ORDER' &&
          datas.type !== 'PROMOTE_EXPIRED_TASK' &&
          datas.type !== 'SUBMIT_ORDER_TASK' &&
          datas.type !== 'TASK_STUDENT_SHARE_AUDIT' &&
          datas.type !== 'TASK_STUDENT_ABSENT' &&
          datas.type !== 'TASK_RESIGN_STUDENT_OLD_CLASSADMIN' &&
          datas.type !== 'TASK_RESIGN_STUDENT_NEW_CLASSADMIN' &&
          datas.type !== 'TASK_FIRST_PASS' &&
          datas.type !== 'TASK_FIRST_FOLLOW_UP' &&
          datas.type !== 'TASK_CREATE_GROUP' &&
          datas.type !== 'NEW_STUDENT_FOLLOW_UP' &&
          datas.type !== 'TASK_SCHEDULE' &&
          datas.type !== 'TASK_PROMOTE' &&
          datas.type !== 'TASK_CLASS_ADMIN_FIRST_PASS' &&
          datas.type !== 'TASK_CLASS_ADMIN_JOIN' &&
          datas.type !== 'TRANSFER_STUDENT_FOLLOW_UP' &&
          datas.type !== 'TASK_MOTH_VISIT' &&
          datas.type !== 'TASK_W2_VISIT' &&
          datas.type !== 'TASK_W3_VISIT' &&
          datas.type !== 'TASK_SUBSCRIBE_COURSE' &&
          datas.type !== 'TASK_TRACK_CREATE' &&
          datas.type !== 'TASK_NOT_RENEW' &&
          datas.type !== 'TASK_PRIVATE_CLASS_REMINDER' &&
          datas.type !== 'TASK_TERM_VISIT' &&
          datas.type !== 'TASK_WEEK_VISIT' &&
          datas.type !== 'REVIEW_QUESTION_REMINDER' &&
          datas.type !== 'TASK_STUDENT_ACTIVE' &&
          datas.type !== 'TASK_MOTH_STUDY_VISIT' &&
          datas.type !== 'TASK_NEXT_FOLLOW_RECORD' &&
          datas.type !== 'TASK_LOSS_REFUND_STUDENT_FIRST_PASS' &&
          datas.type !== 'TASK_STUDENT_CU_EXPIRE_DATE_REMINDER' &&
          datas.type !== 'TASK_STUDENT_MULTI_CHILD' &&
          datas.type !== 'APPLICATION_FOR_TEACHER_EN' &&
          datas.type !== 'TEACHER_LEAVE_FOLLOW' &&
          datas.type !== 'TAKE_OVER_EN' &&
          datas.type !== 'TEACHER_TAKE_OVER_FOLLOW' &&
          datas.type !== 'RESIGN_TAKE_OVER_EN' &&
          datas.type !== 'TEACHER_RESIGN_TAKE_OVER_FOLLOW' &&
          datas.type !== 'SCHEDULE_FEEDBACK' &&
          datas.type !== 'LOW_SCORE_TEST' &&
          datas.type !== 'ATTEMPT_RETENTION_ON_CANCELLATION' &&
          datas.type !== 'TASK_TOPIC_COURSE_SELECTION_FOLLOW_UP'
        ) {
          (async () => {
            // 请假,班级交接没有关联课节
            this.queryCourseSchedule(data.processVariables.courseScheduleId, data.processVariables.studentId);
            if (datas.type === 'STAGE_LEARNING_REPORT') {
              if (this.formInfoData.showNewReport) {
                await this.queryKnowledgeSummery(data.processVariables.courseSectionId);
              } else {
                this.visibleCourse = true;
              }
            } else if (['DAYLIGHT_TIME_CHANGED'].includes(datas.type)) {
              (async () => {
                await this.queryDaylightReadInfo(data.processVariables);
                this.visibleCourse = true;
              })();
            }
          })();
        } else {
          // 新生首通额外的详情信息
          if (['TASK_FIRST_PASS', 'TASK_FIRST_FOLLOW_UP'].includes(datas.type)) {
            (async () => {
              await this.queryTrailScheduleDetail(data.processVariables);
              this.visibleCourse = true;
            })();
          } else if (['APPLICATION_FOR_TEACHER_LEAVE'].includes(datas.type)) {
            (async () => {
              await this.handleGetHandleCourseList(data.processVariables, data.processInstanceId);
              this.visibleCourse = true;
            })();
          } else {
            this.visibleCourse = true;
          }
        }
      });
    },
    onGetformData(data) {
      let params = {};
      switch (data.taskType) {
        // 详情里的添加跟进记录
        case 'addRecord':
          this.openRecordsModal(this.tableListItem);
          break;
        // 详情里的新班主任查看学生资料
        case 'lookStudentInfo':
          this.openStudentDeatil(this.tableListItem);
          break;
        case 'complate':
          if (this.taskInfoDataType === 'LESSON_PROGRESS_RELATED') {
            completeTaskDetail(
              {
                message: data.formData.message,
                userId: this.userInfo.userID,
                taskId: this.operateTaskId,
                processInstanceId: this.tableListItem.processInstanceId,
                taskType: undefined,
                nowTaskType: 'LESSON_PROGRESS_RELATED',
                taskVariables: data?.formData,
                // formData: {
                //   ...(data.formDta ?? {}),
                //   adjustedFirstLesson: {
                //     structure: data.formDta?.adjustedFirstLesson?.courseSectionRespDTO?.structure,
                //     startChinaDateTime: data.formDta?.adjustedFirstLesson?.startChinaDateTime,
                //   },
                // },
                // ...data,
              },
              this.operateTaskId,
              this.tableListItem.processInstanceId,
            ).then(() => {
              this.onCloseCourse();
            });
            return;
          }

          // 升级交接班，老师离职班级交接
          if (this.taskInfoDataType === 'TAKE_OVER' || this.taskInfoDataType === 'CHINESE_RESIGN_TAKE_OVER') {
            const studentIds = this.tableListItem.processVariables.students.map((item) => {
              return item.id;
            });

            // 关联任务
            const relatedTask = {
              processInstanceId: this.tableListItem.processInstanceId,
              id: this.tableListItem.id,
              name: this.tableListItem.processVariables.question,
              type: this.taskInfoDataType,
              taskStatus: data.taskStatus,
              relatedClassId: this.tableListItem.processVariables.relatedClass[0].uuid,
              category: this.tableListItem.category,
              processType: this.tableListItem.processVariables.processType,
            };
            // 找到关联的课节
            const filterArr = this.courseScheduleArr.filter((item) => {
              return item.uuid === data.formData.firstCourseObjId;
            });
            params = {
              message: data.formData.message,
              userId: this.userInfo.userID,
              taskId: this.operateTaskId,
              processInstanceId: this.tableListItem.processInstanceId,
              taskOverIsValidate: data.formData.taskOverIsValidate ?? false,
              nextProcessDto: {
                classType: this.tableListItem.processVariables.classType,
                courseType: this.tableListItem.processVariables.courseType,
                relatedTask,
                firstCourseEndDateTime: filterArr[0] && filterArr[0].endChinaDateTime,
                subject: filterArr[0] && filterArr[0].courseSectionRespDTO.course.subject,
                relatedClass: this.tableListItem.processVariables.relatedClass,
                courseScheduleId: filterArr[0] && filterArr[0].uuid,
                studentIds,
                preTaskType: this.taskInfoDataType,
              },
            };
            // 只有选择首课课节，才去创建新流程
            let createNewProcess = false;
            if (filterArr.length > 0) {
              createNewProcess = true;
            }

            completeTaskDetail1({ ...params, nowTaskType: this.taskInfoDataType }, createNewProcess).then(() => {
              this.onCloseCourse();
            });
          }

          // 数学英文 老师请假
          else if (
            this.taskInfoDataType === 'APPLICATION_FOR_TEACHER_EN' &&
            this.tableListItem?.processVariables?.subject !== 'CHINESE'
          ) {
            const {
              subject,
              relatedClass,
              description,
              teacherId,
              teacherName,
              leaveStartTime,
              leaveEndTime,
              chinaBeginDateTime,
              reason,
              reasonType,
              imgs,
            } = this.tableListItem.processVariables || {};

            const params = {
              createNewProcess: true,
              nextProcessDto: {
                subject,
                relatedClass,
                description,
                teacherId,
                teacherName,
                leaveStartTime,
                leaveEndTime,
                chinaBeginDateTime,
                reason,
                reasonType,
                imgs,
              },
              taskType: 'TEACHER_LEAVE_FOLLOW',
              userId: this.userInfo.userID,
              message: data.formData.message,
            };
            completeTaskDetail(
              { ...params, nowTaskType: this.taskInfoDataType },
              this.operateTaskId,
              this.tableListItem.processInstanceId,
            ).then(() => {
              this.onCloseCourse();
            });
            // 数学英文老师长期交接班
          } else if (
            this.taskInfoDataType === 'TAKE_OVER_EN' &&
            this.tableListItem?.processVariables?.subject !== 'CHINESE'
          ) {
            const {
              subject,
              relatedClass,
              description,
              teacherId,
              startUserName: teacherName,
              outProcedure,
              outWords,
              reasonable,
              classState,
              firstSchedule,
              lastCourseDate,
            } = this.tableListItem.processVariables || {};

            const filterArr = this.courseScheduleArr.filter((item) => {
              return item.uuid === data.formData.firstCourseObjId;
            });

            let params = {
              userId: this.userInfo.userID,
              message: data.formData.message,
              createNewProcess: false,
              taskOverIsValidate: data.formData.taskOverIsValidate ?? false,
            };
            if (data.formData.newSchedule) {
              params = {
                ...params,
                createNewProcess: true,
                taskOverIsValidate: data.formData.taskOverIsValidate ?? false,
                nextProcessDto: {
                  subject,
                  relatedClass,
                  description,
                  teacherId,
                  teacherName,
                  outProcedure,
                  outWords,
                  reasonable,
                  classState,
                  newSchedule: data.formData.newSchedule,
                  firstSchedule,
                  lastCourseDate,
                  firstCourseStartDateTime: filterArr[0] && filterArr[0].startChinaDateTime,
                  firstCourseEndDateTime: filterArr[0] && filterArr[0].endChinaDateTime,
                  courseScheduleId: filterArr[0] && filterArr[0].uuid,
                },
                taskTypes: ['TEACHER_TAKE_OVER_FOLLOW', 'TASK_CHANGE_TEACHER_VISIT'],
              };
            }

            completeTaskDetail(
              { ...params, nowTaskType: this.taskInfoDataType },
              this.operateTaskId,
              this.tableListItem.processInstanceId,
            ).then(() => {
              this.onCloseCourse();
            });
            // 数学英文离职交接班
          } else if (
            this.taskInfoDataType === 'RESIGN_TAKE_OVER_EN' &&
            this.tableListItem?.processVariables?.subject !== 'CHINESE'
          ) {
            const {
              subject,
              workType,
              relatedClass,
              description,
              teacherId,
              teacherName,
              outProcedure,
              classState,
              takeOverDate,
            } = this.tableListItem.processVariables || {};

            const filterArr = this.courseScheduleArr.filter((item) => {
              return item.uuid === data.formData.firstCourseObjId;
            });

            let params = {
              userId: this.userInfo.userID,
              message: data.formData.message,
              createNewProcess: false,
              taskOverIsValidate: data.formData.taskOverIsValidate ?? false,
            };
            if (data.formData.newSchedule) {
              params = {
                ...params,
                createNewProcess: true,
                taskOverIsValidate: data.formData.taskOverIsValidate ?? false,
                nextProcessDto: {
                  workType,
                  subject,
                  relatedClass,
                  description,
                  teacherId,
                  teacherName,
                  outProcedure,
                  classState,
                  newSchedule: data.formData.newSchedule,
                  takeOverDate,
                  firstCourseStartDateTime: filterArr[0] && filterArr[0].startChinaDateTime,
                  firstCourseEndDateTime: filterArr[0] && filterArr[0].endChinaDateTime,
                  courseScheduleId: filterArr[0] && filterArr[0].uuid,
                },
                taskTypes: ['TEACHER_RESIGN_TAKE_OVER_FOLLOW', 'TASK_CHANGE_TEACHER_VISIT'],
              };
            }
            completeTaskDetail(
              { ...params, nowTaskType: this.taskInfoDataType },
              this.operateTaskId,
              this.tableListItem.processInstanceId,
            ).then(() => {
              this.onCloseCourse();
            });
          } else {
            // 如果是学员激活自己完成的弹窗
            if (data.selfActive) {
              params = {
                message: data.formData.message,
                userId: this.userInfo.userID,
                taskId: this.operateTaskId,
                processInstanceId: this.tableListItem.processInstanceId,
                freeze: {
                  freezeFromDate: data.formData.freezeFromDate, // 冻结开始时间
                  freezeToDate: data.formData.freezeToDate, // 冻结结束时间
                },
              };
            } else {
              params = {
                message: data.formData.message,
                userId: this.userInfo.userID,
                taskId: this.operateTaskId,
                processInstanceId: this.tableListItem.processInstanceId,
              };

              // 紧急代课里面调整课节状态
              if (this.taskInfoDataType === 'APPLICATION_FOR_TEACHER_LEAVE') {
                params.adjustScheduleProcess = data.formData.adjustScheduleProcess;
              }
            }

            if (this.taskInfoDataType === 'TASK_CONFIRM_ORDER') {
              params.orderId = this.tableListItem.processVariables.orderId;
              completeTaskDetail2({ ...params, nowTaskType: this.taskInfoDataType }).then(() => {
                this.onCloseCourse();
              });
            } else {
              completeTaskDetail3({ ...params, nowTaskType: this.taskInfoDataType }).then(() => {
                this.onCloseCourse();
              });
            }
          }

          break;
        case 'refuse':
          params = {
            taskId: this.operateTaskId,
            userId: this.userInfo.userID,
            reason: data.formData.reason,
            processInstanceId: this.tableListItem.processInstanceId,
          };
          rejectTaskDetail(params).then(() => {
            this.onCloseCourse();
          });

          break;
        case 'comment':
          params = {
            message: data.formData.message,
            createdBy: this.userInfo.userID,
            taskId: this.operateTaskId,
          };
          addCommentsDetail(params).then(() => {
            this.onCloseOperate();
          });
          break;
        case 'CC':
          params = {
            userIds: data.formData.userIds,
            taskId: this.operateTaskId,
            message: data.formData.message,
            createdBy: this.userInfo.userID,
          };
          copyTaskDetail(params).then(() => {
            this.onCloseOperate();
          });
          break;
        case 'turn':
          params = {
            message: data.formData.message,
            userId: this.userInfo.userID,
            taskId: this.operateTaskId,
            turnAssigneeId: data.formData.turnAssigneeId,
          };
          turnTaskDetail(params).then(() => {
            this.onCloseCourse();
          });
          break;
        // 交接入群
        case 'turnOverGroup':
          this.turnOverGroup(this.tableListItem);
          break;
        case 'goMakeUp':
          this.onGoMakeUp(this.tableListItem);
          break;
        // 完成补升
        case 'finishMakeUp':
          this.operateTaskId = this.tableListItem.id;
          this.studentCode = this.tableListItem.processVariables.studentCode;
          this.studentName = this.tableListItem.processVariables.studentName;
          this.promotionObj = {
            taskId: this.operateTaskId,
            type: this.taskInfoDataType,
            classAdminId: this.userInfo.userID,
            studentId: this.tableListItem.processVariables.student.studentId,
            subject: this.tableListItem.processVariables.student.subject,
            studentName: this.studentName,
            studentCode: this.studentCode,
          };
          this.finishMakeUpFlag = true;
          break;
        case 'createGroup':
          this.createGroup(this.tableListItem);
          break;
        // 查看课表
        case 'lookClassTable':
          const data1 = this.tableListItem.processVariables;
          const conversation_student = {
            fullName: data1.studentName,
            code: data1.studentCode,
            uuid: data1.student.studentId,
            timezone: data1.timezone,
          };
          // 记录选中学生信息
          session.set('studentProfile', conversation_student);
          this.$router.push({ path: '/calendar', query: { uuid: data1.student.studentId } });
          break;
        // 去排课
        case 'ToScheduleClasses':
          const item = {
            studentName: this.tableListItem.processVariables.studentName,
            studentCode: this.tableListItem.processVariables.studentCode,
            studentId: this.tableListItem.processVariables.studentId,
          };
          this.handleNoFirstDate(item);
          break;
        case 'ConfirmAddress':
          this.lookFeedBack(this.tableListItem);
          break;
        default:
          break;
      }
    },
    openStudentDeatil(data) {
      const params = {
        studentId: data.processVariables.studentId,
        oldClassAdminId: data.processVariables.preClassAdmin.preClassAdminId,
      };
      queryStudentInfoData(params).then((res) => {
        console.log(res);
        this.paramsData = res.data.content;
        // this.studentDetail=this.studentInfo.student
        this.visibleAdminStudentInfo = true;
      });

      // this.paramsData={
      //   studentId:data.processVariables.studentId,
      //   oldClassAdminId:data.processVariables.preClassAdmin.preClassAdminId
      // }
    },
    onCloseCourse() {
      this.visibleCourseTC = false;
      this.visibleCourse = false;
      this.operatevisibleTC = false;
      this.visibleActive = false;
      // 关闭详情操作弹窗
      this.operatevisibleWrap = !this.operatevisibleWrap;
      this.refreshTaskTable();
    },
    onCloseOperate() {
      // 关闭详情操作弹窗
      this.operatevisibleWrap = !this.operatevisibleWrap;

      this.lookFeedBack(this.tableListItem);
    },
    // 评论，转交，抄送操作成功，回到详情弹窗
    initOptionsType(value, typeArr) {
      let name = '';
      typeArr?.forEach?.((item) => {
        if (item.value === value) {
          name = item.label;
        }
      });

      return name;
    },
    // 查询表格里得枚举类型
    initOptions() {
      initOptions().then((res) => {
        this.options = res.data.content;
      });
    },
    resetCondition() {
      this.studentCondition = '';
      this.taskName = '';
      this.taskStatus = undefined;
      this.taskTypeName = [];
      this.subjects = [];
      this.queryStartTime = null;
      this.queryEndTime = null;
      this.deptId = undefined;
      this.assigneeId = undefined;
      this.queryClassDateArr = [];
      this.onSearchReport();
    },
    handleTabClick(item) {
      this.activeName = item;

      switch (item) {
        case 'nofinish':
          this.upComingTypeValues = 'UpComing';
          this.selTaskItem();
          break;
        case 'finished':
          this.upComingTypeValues = 'Done';
          this.selFinishTaskItem();
          break;
        case 'copyme':
          this.upComingTypeValues = 'SendMe';
          this.selCopyMeTaskItem();
          break;
        case 'createme':
          this.upComingTypeValues = 'CreateMe';
          this.selCreateMeTaskItem();
          break;
        default:
      }
    },
    // 获取抄送我的列表
    copyMeTaskList() {
      const params = {
        classAdminId: this.userInfo.userID,
      };
      copyMeTaskList(params).then((res) => {
        this.taskCopyMeData = res.data.content;
      });
    },
    // 获取我创建的列表
    createMeTaskList() {
      const params = {
        classAdminId: this.userInfo.userID,
        studentCondition: this.studentCondition,
        taskName: this.taskName,
        taskStatus: this.taskStatus,
      };
      createMeTaskList(params).then((res) => {
        this.taskCreateMeData = res.data.content;
      });
    },
    // 获取已办列表
    finishedTaskList() {
      const params = {
        assigneeId: this.userInfo.userID,
      };
      finishedTaskList(params).then((res) => {
        this.taskFinishData = res.data.content;
      });
    },
    // 已办里面得详情操作
    handleOperateFinished(type, record) {
      switch (type) {
        // 反馈排课相关详情
        case 'lookFeedBack':
          this.operateTaskId = record.id;
          this.lookFeedBack(record);
          break;
        default:
      }
    },
    // 表格里面得操作
    handleOperate(type, record) {
      this.taskInfoDataType = record.type;

      switch (type) {
        // 课节回放
        case 'playCourse':
          this.playCourse(record.processVariables.schedule.scheduleId);
          break;
        // 建群
        case 'createGroup':
          this.createGroup(record);
          break;
        // 跟进记录
        case 'openRecordsModal':
          this.openRecordsModal(record);
          break;
        // 去排课
        case 'handleNoFirstDate':
          const item = {
            studentCode: record.processVariables.studentCode,
            studentName: record.processVariables.studentName,
            studentId: record.processVariables.studentId,
          };
          this.handleNoFirstDate(item);
          break;
        // 交接入群
        case 'turnOverGroup':
          this.turnOverGroup(record);
          break;
        // 去补升
        case 'goMakeUp':
          this.onGoMakeUp(record);
          break;
        // 完成补升
        case 'finishMakeUp':
          this.tableListItem = record;
          this.operateTaskId = record.id;
          this.studentCode = record.processVariables.studentCode;
          this.studentName = record.processVariables.studentName;
          this.promotionObj = {
            taskId: this.operateTaskId,
            type: this.taskInfoDataType,
            classAdminId: this.userInfo.userID,
            studentId: this.tableListItem.processVariables.student.studentId,
            subject: this.tableListItem.processVariables.student.subject,
            studentName: this.studentName,
            studentCode: this.studentCode,
          };
          this.finishMakeUpFlag = true;
          break;
        // 反馈排课相关详情
        case 'lookFeedBack':
          this.operateTaskId = record.id;
          this.tableListItem = record;
          this.lookFeedBack(record);
          break;
        // 查看课表
        case 'lookClassTable':
          const data = record.processVariables;
          const conversation_student = {
            fullName: data.studentName,
            code: data.studentCode,
            uuid: data.student.studentId,
            timezone: data.timezone,
          };
          // 记录选中学生信息
          session.set('studentProfile', conversation_student);
          this.$router.push({ path: '/calendar', query: { uuid: data.student.studentId } });
          break;
        // 完成任务
        case 'finishTask':
          this.operateTaskId = record.id;
          this.tableListItem = record;
          // 如果是学员激活的完成，要先判断一下
          if (record.type === 'TASK_STUDENT_ACTIVE') {
            // 学员激活要单独判断完成
            const params = {
              studentId: record.processVariables.student.studentId,
            };
            studentReminderActive(params).then((res) => {
              if (res.data.content) {
                this.activeForm.resetFields();
                this.visibleActive = true;
              } else {
                this.taskStatusTC = 'complate';
                this.operatevisibleTC = true;

                this.currentViewTC = 'MyTestOperate';
              }
            });
          } else {
            this.taskStatusTC = 'complate';
            this.operatevisibleTC = true;
            this.currentViewTC = 'MyTestOperate';
          }

          break;
        default:
      }
    },
    // 去补升
    onGoMakeUp(record) {
      const parame = {
        dueDate: this.$moment(record.dueDate).format('YYYY-MM-DD HH:mm:ss'),
        processInstanceId: record.processInstanceId,
      };
      checkPromote(parame).then((res) => {
        if (!res.data.content) {
          this.makeUpOverdue = record;
          this.currentViewData = record;
        } else {
          const queryString = Object.entries({
            origin: 'ff',
            studentCode: record.processVariables.studentCode,
            businessId: record.processInstanceId,
            merchantId: '2d6e71ae-c0d6-40b2-9633-48e7dd9d1cea',
            token: _token,
          })
            .map(([k, v]) => `${k}=${encodeURIComponent(v)}`) // 带有特殊字符的需要编码
            .join('&');

          window.open(`${process.env.VUE_APP_MAKEUP_URL}?${queryString}`);
          // 跳转筋斗云订单管理
          // window.open(
          //   `${process.env.VUE_APP_MAKEUP_URL}?studentCode=${record.processVariables.studentCode}&businessId=${record.processInstanceId}&merchantId=2d6e71ae-c0d6-40b2-9633-48e7dd9d1cea&token=${_token}`,
          // );
        }
      });
    },
    // 去补升表单
    onMakeUpOverdue() {
      this.makeUpOverdue = null;
      this.currentView = 'SupplementaryApproval';
    },
    approvaCancel(data) {
      this.currentView = null;
      this.currentViewData = null;
      if (data) {
        this.initOptions();
      }
    },
    // 稍后补升
    cancelMakeUpOverdue() {
      this.currentViewData = null;
      this.makeUpOverdue = null;
    },
    lookFeedBack(record) {
      this.taskInfoDataType = record.type;
      // 判断如果是长期交接班，老师离职班级交接完成才有确认新的首课课节操作
      if (['TAKE_OVER', 'TAKE_OVER_EN', 'CHINESE_RESIGN_TAKE_OVER', 'RESIGN_TAKE_OVER_EN'].includes(record.type)) {
        this.courseScheduleInfo(record);
      }

      this.feedBackDetail(record);
    },
    // 获取已办表格数据
    getFinishedTableList() {
      this.tableFinishLoading = true;
      const params = {
        assigneeId: this.assigneeId,
        deptId: this.deptId,
        categorySet: this.taskTypeName,
        subjects: this.getSearchSubjects(),
        currentPage: this.finishPagination.current,
        pageSize: this.finishPagination.pageSize,
        studentCondition: this.studentCondition,
        dueDateStart: this.queryStartTime,
        dueDateEnd: this.queryEndTime,
        taskStatus: this.taskStatus,
        taskName: this.taskName,
        depthService: this.depthService,
      };
      params.orderBy = this.finishSorter.columnKey;
      if (this.finishSorter.order === 'descend') {
        params.sort = 'desc';
      } else if (this.finishSorter.order === 'ascend') {
        params.sort = 'asc';
      }
      getFinishedTableList(params).then((res) => {
        this.tableFinishLoading = false;
        this.tableFinishData = res.data.content.content;
        this.finishPagination.total = res.data.content.totalCount;
      });
    },
    // 单个交接入群
    turnOverGroup(group) {
      const obj = [
        {
          studentId: group.processVariables.student.studentId,
          subject: group.processVariables.student.subject,
          classAdminId: this.userInfo.userID,
          classAdminName: this.userInfo.fullName,
          taskId: group.id,
        },
      ];
      changeClassAdmin(obj).then(() => {
        this.$message.success('交接入群成功');
        this.getTaskTable();
      });
    },
    // 批量交接入群
    changeClassAdmin() {
      if (this.selectedRows.length === 0) {
        this.$message.warning('请选择群');
        return;
      }
      const groupArr = [];
      this.selectedRows.forEach((item) => {
        const group = item;
        const obj = {
          studentId: group.processVariables.student.studentId,
          subject: group.processVariables.student.subject,
          classAdminId: this.userInfo.userID,
          classAdminName: this.userInfo.fullName,
          taskId: group.id,
        };
        groupArr.push(obj);
      });

      changeClassAdmin(groupArr).then(() => {
        this.$message.success('交接入群成功');
        this.getTaskTable();
      });
    },
    initDate(list) {
      this.startTime = list[0].date;
      this.endTime = list[13].date;
    },
    clickToday() {},

    clickDay(date) {
      this.today = timeUtil.dateFormatOther(new Date(date));
    },
    async playCourseDetail() {
      // 如果是新生首通，单独处理
      let courseScheduleId = '';
      if (['TASK_FIRST_PASS', 'TASK_FIRST_FOLLOW_UP'].includes(this.tableListItem.type)) {
        courseScheduleId = this.trailCourseScheduleId;
      } else {
        courseScheduleId = this.tableListItem.processVariables.courseScheduleId;
      }
      // this.$router.push({ path: '/lessonReplay', query: { courseScheduleId } });

      try {
        const { data } = await getReplayLink(courseScheduleId);
        const videoInfo = data?.[0];
        if (videoInfo?.type === 'HTML_URL') {
          window.open(videoInfo?.link);
        } else {
          window.open(`/lessonReplay?courseScheduleId=${courseScheduleId}`, '_blank');
        }
      } catch {
        window.open(`/lessonReplay?courseScheduleId=${courseScheduleId}`, '_blank');
      }
    },
    // 监课视频处理
    playClassDetail(platform) {
      // 上课平台是classIn，直接打开
      if (platform === 'CLASS_IN') {
        window.open(this.liveUrl, '_blank');
      } else if (platform === 'TALK_CLOUD') {
        // 如果是新生首通，单独处理
        let courseScheduleId = '';
        if (['TASK_FIRST_PASS', 'TASK_FIRST_FOLLOW_UP'].includes(this.tableListItem.type)) {
          courseScheduleId = this.trailCourseScheduleId;
        } else {
          courseScheduleId = this.tableListItem.processVariables.courseScheduleId;
        }
        console.log(courseScheduleId);
      }
    },

    queryPlayCourse(courseScheduleId) {
      const params = {
        courseScheduleId,
      };
      queryPlayCourse(params).then((res) => {
        window.open(res.data.content.inspectorLink, '_blank');
      });
    },
    // 课程回放
    playCourse(scheduleId) {
      const params = {
        scheduleId,
      };
      playbackCourse(params).then((res) => {
        if (res.data.playbackVideos.length === 0) {
          this.$message.error('无法找到此课节视频源');
        } else {
          const urlStr = `/playback?uuid=${scheduleId}`; // 课节得id
          const $a = document.createElement('a');
          $a.style.display = 'none';
          $a.setAttribute('href', urlStr);
          $a.setAttribute('rel', 'opener');
          $a.setAttribute('target', '_blank');
          document.body.appendChild($a);
          $a.click();
          setTimeout(() => {
            document.body.removeChild($a);
          }, 1000);
        }
      });
    },

    // 查询日历标记点
    queryCalendar() {
      const params = {
        classAdminId: this.userInfo.userID,
        startTime: `${timeUtil.dateFormatOther(new Date(this.startTime))}T00:00:00`,
        endTime: `${timeUtil.dateFormatOther(new Date(this.endTime))}T23:59:59`,
      };
      queryCalendar(params).then((res) => {
        const calendarObj = res.data;
        Object.keys(calendarObj).forEach((key) => {
          const obj = {
            date: key,
            className: ['mark1'],
          };
          this.markDateMore.push(obj);
        });
      });
    },
    handleCancelGroup() {
      this.groupVisible = false;
    },
    handleNoFirstDateTC() {
      const item = {
        studentName: this.tableListItem.processVariables.studentName,
        studentCode: this.tableListItem.processVariables.studentCode,
        studentId: this.tableListItem.processVariables.studentId,
      };
      this.handleNoFirstDate(item);
    },

    handleNoFirstDate(item) {
      const conversation_student = {
        fullName: item.studentName,
        code: item.studentCode,
        uuid: item.studentId,
      };
      // 记录选中学生信息
      session.set('studentProfile', conversation_student);

      this.$router.push({ path: '/toTaskScheduleClasses' });
    },
    handleCancelNoDate() {
      this.noTimevisible = false;
    },
    handleCancelFirstDate() {
      this.visibleModal = false;
    },
    handleOkFirstDate(obj) {
      const params = {
        classAdminId: this.userInfo.userID,
        studentId: this.currentStudent.processVariables.student.studentId,
        type: this.taskInfoDataType,
        subject: this.currentStudent.processVariables.student.subject,
        studentCode: this.currentStudent.processVariables.studentCode,
        firstCourseDate: obj.firstLesson,
        processInstanceId: this.processInstanceId,
        firstCourseScheduleId: obj.firstCourseScheduleId,
        firstFromClassId: obj.firstFromClassId,
        remark: obj.remark,
        firstCourseScheduleName: obj.firstCourseScheduleName,
        firstFromClassName: obj.firstFromClassName,
        firstCourseEndDate: obj.firstCourseEndDate,
      };
      // 新生首通
      if (!['TASK_FIRST_PASS', 'TASK_FIRST_FOLLOW_UP'].includes(this.tableListItem.type)) {
        params.taskId = this.currentStudent.id;
      }
      confirmFirstLesson(params).then(() => {
        this.$message.success('确认首课成功');
        this.visibleModal = false;
        // this.lookFeedBack(this.tableListItem);
        // this.handleGetSelfBookingInfo(this.currentStudent.processVariables.student.studentId,this.currentStudent.processVariables.student.subject)
        this.handleGetSelfBookingInfo(
          this.currentStudent.processVariables.student.studentId,
          this.currentStudent.processVariables.student.subject,
        );
        this.getTaskTable();
      });
    },

    // 创建群
    createGroup(record) {
      const params = {
        taskId: record.id,
        classAdminId: this.userInfo.userID,
        classAdminName: this.userInfo.fullName,
        subject: record.processVariables.student.subject,
        studentId: record.processVariables.student.studentId,
        studentName: record.processVariables.studentName,
      };
      createGroup(params).then((res) => {
        this.groupID = res.data.content.groupId;
        this.groupName = res.data.content.groupName;
        this.$message.success('创建群成功');
        this.groupVisible = true;
        this.studentName = record.processVariables.studentName;
        this.createGroupObj = {
          groupID: this.groupID,
          groupName: this.groupName,
          studentName: this.studentName,
        };
        this.getTaskTable();
      });
    },
    closeRecordModal() {
      this.visible = false;
    },
    openRecordsModal(item) {
      this.taskObj = {
        studentName: item.processVariables.studentName,
        studentCode: item.processVariables.studentCode,
        subjectType: item.subjectType,
        taskId: item.id,
        followType: 'TASK',
        taskName: item.category,
        type: item.type,
        processInstanceId: item.processInstanceId,
        firstCourseDate: item.processVariables.firstCourseDate ? item.processVariables.firstCourseDate : '',
      };
      if (['TASK_FIRST_PASS', 'TASK_FIRST_FOLLOW_UP'].includes(item.type)) {
        this.taskObj.selfBookingInfo = this.formInfoData?.selfBookingInfo;
      }
      if (item.type === 'STAGE_LEARNING_REPORT') {
        this.taskObj.studentId = item.processVariables.students[0].id;
        this.taskObj.subject = item.processVariables.subject;
      } else {
        this.taskObj.studentId = item.processVariables.student.studentId;
        this.taskObj.subject = item.processVariables.student.subject;
      }
      if (item.type === 'TASK_CHANGE_TEACHER_VISIT' || item.type === 'TASK_LONG_TASK_OVER_VISIT') {
        this.taskObj.subject = item.processVariables.student.subject;
        this.taskObj.teacherName = this.formInfoData?.courseInfo?.teacherName;
      }
      if (item.type === 'TASK_FIRST_VISIT') {
        this.taskObj.teacherName = item.processVariables.schedule.teacherName;
        //  返回首课级别
        this.taskObj.firstClassLevel = this.formInfoData.courseInfo.courseSectionName;
      }
      if (item.type === 'TASK_NOT_RENEW') {
        this.taskObj.userRole = item.processVariables.userRole;
      }
      if (item.type === 'ATTEMPT_RETENTION_ON_CANCELLATION') {
        this.taskObj.studentId = item.processVariables.student.studentId;
        this.taskObj.subject = item.processVariables.student.subject;
        this.taskObj.subscriptionId = item.processVariables.subscriptionId;
      }
      if (item.type === 'TASK_TOPIC_COURSE_SELECTION_FOLLOW_UP') {
        this.taskObj.courseId = item.processVariables.courseId;
      }
      if (item.type === 'DAYLIGHT_TIME_CHANGED') {
        this.taskObj.studentScheduleId = item.processVariables.studentScheduleId;
        this.taskObj.daylightReadInfo = this.formInfoData.daylightReadInfo;
      }
      this.visible = true;
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      console.log(selectedRows);
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
    },

    // 获取待办里的表格数据
    getTaskTable() {
      this.tableLoading = true;
      const params = {
        categorySet: this.taskTypeName,
        subjects: this.getSearchSubjects(),
        assigneeId: this.assigneeId,
        dueDateStart: this.queryStartTime,
        dueDateEnd: this.queryEndTime,
        deptId: this.deptId,
        // startTime: `${this.today}T23:59:59`,
        currentPage: this.nofinishPagination.current,
        pageSize: this.nofinishPagination.pageSize,
        studentCondition: this.studentCondition,
        taskName: this.taskName,
        taskStatus: this.taskStatus,
        type: this.noFinishFlag,
        taskId: this.queryTaskId,
        // endTime: `${this.today}T23:59:59`
        onlyWaking: this.filterTimeStatus,
        depthService: this.depthService,
      };
      if (this.$route.query.studentCode && this.showAllFlag) {
        params.showAll = true;
        params.subjects = [];
      }
      params.orderBy = this.noFinishSorter.columnKey;
      if (this.noFinishSorter.order === 'descend') {
        params.sort = 'desc';
      } else if (this.noFinishSorter.order === 'ascend') {
        params.sort = 'asc';
      }
      getTaskTableList(params).then((res) => {
        this.tableLoading = false;
        this.tableNoFinishData = res.data.content.content;
        this.nofinishPagination.total = res.data.content.totalCount;
      });
    },
    selCreateMeTaskItem() {
      this.createFirstFlag = false;

      this.createMeColumns = [
        {
          title: '序号',
          key: 'index',
          width: 60,
          dataIndex: 'num',
          customRender: (text, record, index) =>
            `${(this.createMePagination.current - 1) * this.createMePagination.pageSize + (index + 1)}`,
        },
        {
          title: '任务名称',
          dataIndex: 'name',
          key: 'name',
          width: 220,
          ellipsis: true,
          customRender: (_, record) => (record.processVariables.depthService ?? '') + record.name,
          customCell: (record) => {
            return {
              style: {
                // 行背景色
                color: '#3372FE',
                cursor: 'pointer',
              },
              on: {
                // 鼠标单击行
                click: () => this.handleOpenTaskDetail(record),
              },
            };
          },
        },
        {
          title: '学生',
          dataIndex: 'processVariables.studentName',
          key: 'studentName',
          width: 220,
          ellipsis: true,
          scopedSlots: { customRender: 'studentName' },
          customCell: (record) => {
            return {
              style: {
                // 行背景色
                // color: '#43D186',
                cursor: 'pointer',
              },
              on: {
                // 鼠标单击行
                click: () => {
                  if (record.processVariables.studentId && record.type !== 'TASK_RESIGN_STUDENT_NEW_CLASSADMIN') {
                    const routeUrl = this.$router.resolve({
                      path: '/studentDetail',
                      query: {
                        uuid: record.processVariables.studentId,
                      },
                    });
                    window.open(routeUrl.href, '_blank');
                  } else {
                    this.$message.warning('暂无权限访问');
                  }
                },
              },
            };
          },
        },
        {
          title: '任务截止时间',
          dataIndex: 'processVariables.dueDate',
          key: 'dueDate',
          scopedSlots: { customRender: 'dueDate' },
          sorter: true,
        },
        {
          title: '任务状态',
          dataIndex: 'processVariables.finished',
          key: 'finished',
          scopedSlots: { customRender: 'finished' },
        },

        {
          title: '当前节点处理人',
          dataIndex: 'processVariables.assignees',
          key: 'assignees',
          width: 220,
          ellipsis: true,
        },
        {
          title: '处理时间',
          dataIndex: 'processVariables.endTime',
          key: 'endTime',
          sorter: true,
        },
      ];
      this.createMePagination = { ...this.initPagination };

      this.getCreateMeTableList();
    },
    selCopyMeTaskItem() {
      this.copyFirstFlag = false;

      this.copyMeColumns = [
        {
          title: '序号',
          key: 'index',

          width: 60,

          dataIndex: 'num',
          customRender: (text, record, index) =>
            `${(this.copyMePagination.current - 1) * this.copyMePagination.pageSize + (index + 1)}`,
        },
        {
          title: '任务名称',
          dataIndex: 'name',
          key: 'name',
          width: 220,
          ellipsis: true,
          customRender: (_, record) => (record.processVariables.depthService ?? '') + record.name,
          customCell: (record) => {
            return {
              style: {
                // 行背景色
                color: '#3372FE',
                cursor: 'pointer',
              },
              on: {
                // 鼠标单击行
                click: () => this.handleOpenTaskDetail(record),
              },
            };
          },
        },
        {
          title: '学生',
          dataIndex: 'processVariables.studentName',
          key: 'studentName',
          width: 220,
          ellipsis: true,
          scopedSlots: { customRender: 'studentName' },
          customCell: (record) => {
            return {
              style: {
                // 行背景色
                // color: '#43D186',
                cursor: 'pointer',
              },
              on: {
                // 鼠标单击行
                click: () => {
                  if (record.processVariables.studentId && record.type !== 'TASK_RESIGN_STUDENT_NEW_CLASSADMIN') {
                    const routeUrl = this.$router.resolve({
                      path: '/studentDetail',
                      query: {
                        uuid: record.processVariables.studentId,
                      },
                    });
                    window.open(routeUrl.href, '_blank');
                  } else {
                    this.$message.warning('暂无权限访问');
                  }
                },
              },
            };
          },
        },
        {
          title: '任务截止时间',
          dataIndex: 'processVariables.dueDate',
          key: 'dueDate',
          scopedSlots: { customRender: 'dueDate' },
          sorter: true,
        },
        {
          title: '任务状态',
          dataIndex: 'processVariables.finished',
          key: 'finished',
          scopedSlots: { customRender: 'finished' },
        },

        {
          title: '当前节点处理人',
          dataIndex: 'processVariables.assignees',
          key: 'assignees',
        },
        {
          title: '处理时间',
          dataIndex: 'processVariables.endTime',
          key: 'endTime',
          sorter: true,
        },
      ];
      this.copyMePagination = { ...this.initPagination };

      this.getCopyMeTableList();
    },
    selFinishTaskItem() {
      this.finishFirstFlag = false;
      this.finishPagination = { ...this.initPagination };
      this.finishColumns = [
        {
          title: '序号',
          key: 'index',

          width: 60,

          dataIndex: 'num',
          customRender: (text, record, index) =>
            `${(this.finishPagination.current - 1) * this.finishPagination.pageSize + (index + 1)}`,
        },
        {
          title: '任务名称',
          dataIndex: 'name',
          key: 'name',
          width: 220,
          ellipsis: true,
          customRender: (_, record) => (record.processVariables.depthService ?? '') + record.name,
          customCell: (record) => {
            return {
              style: {
                // 行背景色
                color: '#3372FE',
                cursor: 'pointer',
              },
              on: {
                // 鼠标单击行
                click: () => this.handleOpenTaskDetail(record),
              },
            };
          },
        },
        {
          title: '学生',
          dataIndex: 'processVariables.studentName',
          key: 'studentName',
          width: 220,
          ellipsis: true,
          scopedSlots: { customRender: 'studentName' },
          customCell: (record) => {
            return {
              style: {
                // 行背景色
                // color: '#43D186',
                cursor: 'pointer',
              },
              on: {
                // 鼠标单击行
                click: () => {
                  // this.$store.commit('setStudentName', record.processVariables.studentName);
                  // this.$store.commit('setStudentIdRoute', record.processVariables.student.studentId);
                  // const routeUrl = this.$router.resolve({
                  //   path: '/myStudent',
                  // });
                  // window.open(routeUrl.href, '_blank');
                  // this.$router.push({ path: '/myStudent' });
                  if (record.processVariables.studentId && record.type !== 'TASK_RESIGN_STUDENT_NEW_CLASSADMIN') {
                    const routeUrl = this.$router.resolve({
                      path: '/studentDetail',
                      query: {
                        uuid: record.processVariables.studentId,
                      },
                    });
                    window.open(routeUrl.href, '_blank');
                  } else {
                    this.$message.warning('暂无权限访问');
                  }
                },
              },
            };
          },
        },
        {
          title: '任务截止时间',
          dataIndex: 'processVariables.dueDate',
          key: 'dueDate',
          scopedSlots: { customRender: 'dueDate' },
          sorter: true,
        },
        {
          title: '任务状态',
          dataIndex: 'processVariables.finished',
          key: 'finished',
          scopedSlots: { customRender: 'finished' },
        },
        {
          title: '处理时间',
          dataIndex: 'processVariables.endTime',
          key: 'endTime',
          sorter: true,
        },
      ];
      this.getFinishedTableList();
    },
    selTaskItem() {
      this.nofinishFirstFlag = false;

      this.nofinishPagination = { ...this.initPagination };

      this.noFinishColumns = [
        {
          title: '序号',
          key: 'index',
          width: 60,
          dataIndex: 'num',
          customRender: (text, record, index) =>
            `${(this.nofinishPagination.current - 1) * this.nofinishPagination.pageSize + (index + 1)}`,
        },
        {
          title: '任务名称',
          dataIndex: 'name',
          key: 'name',
          width: 220,
          ellipsis: true,
          customRender: (_, record) => (record.processVariables.depthService ?? '') + record.name,
          customCell: (record) => {
            return {
              style: {
                // 行背景色
                color: '#3372FE',
                cursor: 'pointer',
              },
              on: {
                // 鼠标单击行
                click: () => this.handleOpenTaskDetail(record),
              },
            };
          },
        },
        {
          title: '学生',
          dataIndex: 'processVariables.studentName',
          key: 'studentName',
          width: 180,
          ellipsis: true,
          scopedSlots: { customRender: 'studentName' },
          customCell: (record) => {
            return {
              style: {
                // 行背景色
                // color: '#43D186',
                cursor: 'pointer',
              },
              on: {
                // 鼠标单击行
                click: () => {
                  if (record.processVariables.studentId && record.type !== 'TASK_RESIGN_STUDENT_NEW_CLASSADMIN') {
                    const routeUrl = this.$router.resolve({
                      path: '/studentDetail',
                      query: {
                        uuid: record.processVariables.studentId,
                      },
                    });
                    window.open(routeUrl.href, '_blank');
                  } else {
                    this.$message.warning('暂无权限访问');
                  }
                },
              },
            };
          },
        },
        {
          title: '国家',
          scopedSlots: { customRender: 'country' },
        },
        {
          title: '当地时间',
          dataIndex: 'studentDateTime',
          key: 'studentDateTime',
        },
        {
          title: '沟通倒计时',
          scopedSlots: { customRender: 'availableTalk' },
        },
        {
          title: '任务截止时间',
          dataIndex: 'processVariables.dueDate',
          key: 'dueDate',
          scopedSlots: { customRender: 'dueDate' },
          sorter: true,
        },
        {
          title: '创建人',
          dataIndex: 'processVariables.startUserName',
          key: 'startUserName',
        },
        {
          title: '任务等级',
          dataIndex: 'processVariables.depthService',
          key: 'depthService',
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          key: 'createTime',
          sorter: true,
        },
        {
          title: '当前节点处理人',
          dataIndex: 'currentAssignName',
          key: 'currentAssignName',
        },
        // {
        //   title: '操作',
        //   key: 'action',
        //   fixed: 'right',
        //   width: 200,
        //   scopedSlots: { customRender: 'action' },
        // },
      ];

      this.getTaskTable();
    },
    getSubjects() {
      let subjectLabel = '';

      console.log(this.subjects);
      if (this.subjects.length > 0) {
        subjectLabel = this.subjects.join(',');
      } else {
        subjectLabel = this.userInfo.subject
          ? `【${this.initOptionsType(this.userInfo.subject, this.subjectArr)}】`
          : '';
      }
      return subjectLabel;
    },

    getSubjectsArr() {
      let subjectLabel = [];
      if (this.subjects.length > 0) {
        subjectLabel = this.subjects;
      } else {
        this.userInfo.subject
          ? subjectLabel.push(`【${this.initOptionsType(this.userInfo.subject, this.subjectArr)}】`)
          : [];
      }

      return subjectLabel;
    },
    // 获取下拉的任务类型
    getTaskTypeArr() {
      const params = {
        subjects: this.getSubjects(),
      };
      getTaskTypeArr(params).then((res) => {
        this.taskTypeArr = res.data.content;
      });
    },
  },
  created() {
    // 处理从首页跳转
    this.handleFormHome();

    this.queryTaskId = this.$route.query.taskId ? this.$route.query.taskId : '';
    const obj = storage.get('userInfo');
    this.userInfo = obj;

    // 只有教务、教务主管、班主任主管才能筛选科目
    if (
      this.userInfo.roleArr.includes('TEACHING_STAFF') ||
      this.userInfo.roleArr.includes('TEACHING_STAFF_ADMIN') ||
      this.userInfo.roleArr.includes('HEAD_TEACHER_SUPER')
    ) {
      this.showSubject = true;
      this.subjects = this.getSubjectsArr();
    }

    if (this.$route.query.studentCode) {
      this.studentCondition = this.$route.query.studentCode;
    } else if (this.userInfo.leader) {
      this.searchClassAdmin(this.userInfo.fullName);
      this.assigneeId = this.userInfo.userID;
    }

    if (this.isComp) {
      this.studentCondition = this.compFromStudentCode;
      this.assigneeId =
        this.userInfo.roleArr.includes('CLASS_ADMIN') && !this.userInfo.leader ? this.userInfo.userID : undefined;
    }

    this.selTaskItem();
    this.initOptions();
    this.getTaskTypeArr();
    this.getTaskStatistics();
    if (this.userInfo.leader) {
      this.getTreeList();
    }

    getCountryOptions().then((data) => (this.countryOptions = data));
  },
  mounted() {
    // 标记日历有任务得点
    //   this.queryCalendar()
  },
};
</script>

<style lang="less" scoped>
/deep/ .table-page-search-wrapper .ant-form-inline .ant-form-item > .ant-form-item-label {
  width: 105px;
  text-align: right;
}
.table-page-search-wrapper {
  background-color: #fff;
  margin-bottom: 8px;
  padding: 20px 20px 0;
}
.ant-advanced-search-form {
  /deep/ .ant-col-16 {
    width: 70%;
  }
  /deep/ .ant-form-item-label {
    width: 68px;
  }
  /deep/ .ant-form-item-label {
    width: 68px;
  }
}

.home-wrap {
}
.table-wrap {
  padding: 20px;
  background: #fff;
  &.comp {
    padding: 0;
  }
}
.sel-navwrap {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.main-title {
  font-size: 20px;
  font-weight: 600;
  color: #333;
}
.task-list {
  margin-top: 20px;
}
.task-item {
  display: flex;
  cursor: pointer;
}
.task-title {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #333333;
  display: flex;
  align-items: center;
}
.ant-list-split .ant-list-item {
  border-bottom: none;
}
.ant-list-item {
  padding: 12px 0 0 0;
}

.explain {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: url('~@/assets/explain.png') no-repeat center;
  background-size: 100%;
  margin-left: 10px;
}
.task-ulWrap {
  max-width: 600px;
  list-style: disc;
  padding-left: 20px;
  color: #009cff;
  span {
    color: #333;
    font-size: 16px;
  }
}
.task-explainTitle {
  font-size: 18px;
  font-weight: 600;
  padding-bottom: 5px;
}
.arrow-icon {
  width: 12px;
  height: 12px;
  background: url('~@/assets/home_icon_enter.png') no-repeat center;
  background-size: 100%;
  margin-left: 5px;
}
.task-nav {
  background-color: #fff;
  margin-bottom: 8px;
  padding: 20px 0 0;
  display: flex;
  justify-content: space-between;
}

.button-box {
  display: flex;
  justify-content: flex-end;
  padding-right: 10px;
  padding-top: 5px;
}

.refresh-icon {
  width: 25px;
  height: 25px;
  background: url('~@/assets/refresh.png') no-repeat center;
  background-size: 100%;
  display: inline-block;
  margin-right: 10px;
  cursor: pointer;
}
.task-navleft {
  display: flex;
  align-items: center;
}
.refresh-tasknum {
  font-size: 15px;
  font-weight: 600;
  color: #333;
}
.success-icon {
  width: 19px;
  height: 19px;
  background: url('~@/assets/service_icon_arrange_can.png') no-repeat center;
  background-size: 100%;
  display: inline-block;
  margin-right: 10px;
}

/deep/ .ant-drawer-body {
  overflow-y: auto;
  overflow-x: hidden;
}

.sel-wrap {
  height: 58vh;
  overflow-y: auto;
}
.sel-wrap1 {
  height: 80vh;
  overflow-y: auto;
}
.addTask {
  padding: 2px 10px;
  border: 1px solid #eee;
  border-radius: 6px;
  color: #43d186;
  font-size: 14px;
  cursor: pointer;
}
.selActive {
  background: #41cc83;
  color: #fff;
}
.approval-wrap {
  display: flex;
}
.approval-item {
  padding: 15px 15px;
  border: 1px solid #c3c0c0;
  cursor: pointer;
  border-radius: 10px;
  margin-right: 10px;
}
.approval-txt {
  font-size: 14px;
}
/deep/ .el-tabs__item.is-active {
  color: #43d186;
}
/deep/ .el-tabs__active-bar {
  background-color: #43d186;
}
/deep/ .el-tabs__item:hover {
  color: #43d186;
}
.task-navright {
  display: flex;
}
.switch-wrap {
  padding: 5px 12px;
  background-color: #f4f4f4;
  display: inline-block;
  margin-right: 15px;
  border-radius: 5px;
}
.operator-btn {
  color: #1f2d3d;
  font-size: 13px;
  padding: 5px 8px;
  margin-left: 10px;
  border: 1px solid rgba(31, 45, 61, 0.18);
  border-radius: 2px;
  cursor: pointer;
  &:hover {
    border: 1px solid #00bf8a;
    color: #00bf8a;
  }
}
// .sel-task {
//   /deep/ .ant-select-selection {
//     border: none;
//   }
//   /deep/ .ant-select-selection:active {
//     box-shadow: none;
//   }
// }
/deep/ .ant-btn-primary {
  box-shadow: none;
}
.spin-wrap {
  position: absolute;
  top: 300px;
  left: 330px;
}
</style>
